<template>
    <oTable
        :headers="campaignTableHeaders"
        :items="campaignTableItems"
        order-by="campaignGroupName"
        order="ASC"
        paginationMethod="intersection"
        :border-radius="24"
        class="campaigns-table"
        fixedLayout
    >
        <template v-if="searchQuery && campaignTableItems.length === 0" #empty-state>
            <div class="campaigns-empty-state-query-container">
                <Text as="h2" size="f-2">
                    {{ searchQuery }}
                </Text>
                <Text size="f-8" align="center">No campaigns matching your search query.</Text>
            </div>
        </template>
        <template v-if="!searchQuery && campaignTableItems.length === 0" #empty-state>
            <div class="campaigns-empty-state-container">
                <Text size="f-8" align="center" class="paragraph">
                    Your account does not appear to have any campaigns eligible for use with N-Gram
                    Tool.
                </Text>
            </div>
        </template>
        <template #header.campaignName>
            <Spacer width="1.875rem" />
            <Tooltip content="Select All" placement="top">
                <Checkbox @click="selectAllCampaigns" :model-value="allCampaignsSelected" />
            </Tooltip>
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">Campaign</Text>
            <Spacer width="1.25rem" />
            <oInput ref="campaignSearchInput" class="table-search-input" v-model="searchQuery" />
        </template>

        <template #column.campaignName="{ value, row }">
            <!-- add click to the table cell for a larger clickable area -->
            <div class="campaign-cell" @click="toggleCampaign(row.campaignId)">
                <Spacer width="1.875rem" />
                <div v-if="addingNgramsToNegative" class="spinner-container">
                    <Spinner />
                </div>
                <Checkbox v-else :model-value="!excludedCampaigns?.includes(row.campaignId)" />
                <Spacer width="1.25rem" />
                <Tooltip
                    :content="value"
                    :offset="[-8, 8]"
                    :delay="[400, 0]"
                    :visibleOnlyWhenOverflow="true"
                >
                    <EntityPill :type="Improvement.LocationEntity.Campaign" :content="value" />
                </Tooltip>
            </div>
        </template>
        <template #column.cost="{ value }">
            <Money :value="value" :currency="currencyCode" />
        </template>
        <template #column.conversions="{ value }">
            <Number :value="value" />
        </template>
        <template #column.conversionValue="{ value }">
            <Money :value="value" :currency="currencyCode" />
        </template>
        <template #column.cpa="{ value }">
            <Money :value="value" :currency="currencyCode" />
        </template>
        <template #column.roas="{ value }">
            <Percent :value="value" />
        </template>
        <template #header.campaignGroupName>
            <div class="campaign-group-cell-header">Campaign Group</div>
        </template>
        <template #column.campaignGroupName="{ value }">
            <div class="campaign-group-cell">
                <EntityPill :type="Improvement.LocationEntity.CampaignGroup" :content="value" />
            </div>
        </template>
    </oTable>
</template>

<script setup lang="ts">
import { Ref, onMounted, ref, nextTick, onUnmounted } from 'vue'
import { Improvement } from '@opteo/types'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import {
    oTable,
    EntityPill,
    Spacer,
    Checkbox,
    Text,
    Tooltip,
    oInput,
    Spinner,
    Money,
    Number,
    Percent,
    HelpIcon,
    useShortKey,
} from '@opteo/components-next'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { onClickOutside } from '@vueuse/core'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'

const props = defineProps<{
    buttonRef: Ref<HTMLElement>
}>()

const emit = defineEmits(['clickOutside'])

const {
    searchQuery,
    allCampaignsSelected,
    selectAllCampaigns,
    toggleCampaign,
    campaignTableHeaders,
    campaignTableItems,
    addingNgramsToNegative,
    currencyCode,
} = useNGramTool()

const { excludedCampaigns } = useNGramFilters()

const campaignsTableWrapper = ref<HTMLDivElement>()
const campaignSearchInput = ref()

const { activate: activateFocus, deactivate: deactivateFocus } = useFocusTrap(
    campaignsTableWrapper,
    {
        initialFocus: false,
        allowOutsideClick: true,
        setReturnFocus: () => {
            return props.buttonRef.elementRef
        },
    }
)

onClickOutside(campaignsTableWrapper, event => {
    if ((event.target as HTMLElement).closest('.campaigns-table-button')) return
    emit('clickOutside', event)
})

useShortKey({
    keys: ['esc'],
    onPressCallBack: event => {
        emit('clickOutside', event)
    },
})

onMounted(async () => {
    await nextTick()
    campaignSearchInput.value?.inputRef.focus()
    document.body.classList.add('no-scroll')
    activateFocus()
})

onUnmounted(() => {
    searchQuery.value = ''
    document.body.classList.remove('no-scroll')
    deactivateFocus()
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaigns-empty-state-query-container {
    @include br-24;
    @include flex-center;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    padding: 6rem 0;
}
.campaigns-empty-state-container {
    @include br-24;
    @include flex-center;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    padding: 7rem 0;
}
.campaigns-empty-state-container .paragraph {
    max-width: 300px;
}

.campaigns-empty-state-text {
    @include f-8;
    @include lh-150;
    @include tc;
    width: 26rem;
}

.campaign-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.campaign-cell::after {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1rem;
    width: 6rem;
    bottom: 0;
    pointer-events: none;
}

:deep(.campaign-group-cell-header) {
    @include pl-24;
}
:deep(.campaign-group-cell) {
    position: relative;
    @include pl-24;
    width: calc(100% - 1.5rem);
    overflow: hidden;
}
:deep(.campaign-group-cell::after) {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1.5rem;
    width: 3rem;
    bottom: 0;
}
</style>
