<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
    >
        <template #content>
            <div v-for="copy in getSectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1.5rem" />
            <Text as="h6" weight="600">Matched vs. Unmatched</Text>
            <Spacer height="2rem" />
            <div class="data-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <DonutChart
                    :showKeys="true"
                    :items="formatDonutChartData(details?.pie_data ?? [])"
                    :metric="{ label: 'Cost', dataType: 'percentage' }"
                />

                <oTable
                    class="table"
                    :headers="formattedTableHeaders"
                    :items="formattedTableItems"
                    :fixed-layout="true"
                    no-shadow
                    :border-radius="0"
                    responsive-mode="columns"
                    responsive-breakpoint="767px"
                >
                    <!-- Status column -->
                    <template #column.status="status">
                        <Text as="p" size="f-8">{{ status.value }}</Text>
                    </template>

                    <!-- Cost column -->
                    <template #column.cost="cost">
                        <Money :currency="domainCurrency" :value="cost.value" />
                    </template>

                    <!-- Conversions column (CPA) -->
                    <template #column.conversions="conversions">
                        <NumberVue :value="conversions.value" />
                    </template>

                    <!-- Conversions Value column (ROAS) -->
                    <template #column.conversionsValue="conversionsValue">
                        <Money :currency="domainCurrency" :value="conversionsValue.value ?? 0" />
                    </template>

                    <!-- CPA column -->
                    <template #column.cpa="cpa">
                        <Money :currency="domainCurrency" :value="cpa.value" />
                    </template>

                    <!-- ROAS column -->
                    <template #column.roas="roas">
                        <Roas :value="roas.value ?? 0" />
                    </template>

                    <!-- Avg column -->
                    <template #column.vsAvg="vsAvg">
                        <Text
                            as="span"
                            v-if="!vsAvg.value || vsAvg.value === 0"
                            color="gray"
                            weight="500"
                        >
                            <Percent :value="0" :decimal-places="2" :include-sign="true" />
                        </Text>
                        <Text
                            v-else-if="isUsingCpa && vsAvg.value"
                            as="span"
                            :color="vsAvg.value > 0 ? 'red' : 'green'"
                            weight="500"
                        >
                            <Percent
                                :value="vsAvg.value"
                                :decimal-places="1"
                                :include-sign="true"
                            />
                        </Text>
                        <Text
                            v-else
                            as="span"
                            :color="vsAvg.value < 0 ? 'red' : 'green'"
                            weight="500"
                        >
                            <Percent
                                :value="vsAvg.value"
                                :decimal-places="1"
                                :include-sign="true"
                            />
                        </Text>
                    </template>
                </oTable>
            </div>
            <!-- Spacer -->
            <Spacer height="1.5rem" />
            <!-- Note -->
            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Score weighted by cost. Includes only Search campaigns. Metrics based on 10,000
                    most costly search terms over the last 90 days.
                </Text>
            </ImprovementNote>

            <Spacer height="2.5rem" />

            <!-- Search Term Visibility -->
            <Text as="h6" weight="600">Search Term Visibility</Text>
            <Spacer height="2rem" />

            <oTable
                :headers="searchTermVisTableHeaders"
                :items="searchTermVisTableItems"
                :fixed-layout="true"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
                convc
            >
                <!-- Status column -->
                <template v-slot:[`column.type`]="type">
                    <Text as="p" size="f-8">{{ type.value }}</Text>
                </template>

                <!-- Cost column -->
                <template v-slot:[`column.cost`]="cost">
                    <Money :currency="domainCurrency" :value="cost.value" />
                </template>

                <!-- Cost(%) column -->
                <template v-slot:[`column.costPercent`]="costPercent">
                    <Percent :value="costPercent.value" :decimal-places="2" />
                </template>

                <!-- Conversions column -->
                <template v-slot:[`column.conversions`]="conversions">
                    <NumberVue :value="conversions.value" />
                </template>

                <!-- Conversions Value column -->
                <template v-slot:[`column.conversionsValue`]="conversionsValue">
                    <Money :currency="domainCurrency" :value="conversionsValue.value" />
                </template>

                <!-- CPA column -->
                <template v-slot:[`column.cpa`]="cpa">
                    <Money :currency="domainCurrency" :value="cpa.value" />
                </template>

                <!-- ROAS column -->
                <template v-slot:[`column.roas`]="roas">
                    <Roas :value="roas.value" />
                </template>
            </oTable>
        </template>
    </ScorecardSection>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, inject } from 'vue'
import sumBy from 'lodash-es/sumBy'
import { Scorecard } from '@opteo/types'
import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Roas,
    Number as NumberVue,
    DonutChart,
} from '@opteo/components-next'

// Local imports
import Skeleton from '@/components/util/Skeleton.vue'

import { TextOptions, getSectionCopy, formatDonutChartData } from './utils'
import ScorecardSection from './ScorecardSection.vue'

export default defineComponent({
    name: 'SearchTermCoverage',
    props: {
        score: {
            type: Number,
        },
        isLoading: {
            type: Boolean,
        },
        invalid: {
            type: Boolean,
        },
        domainCurrency: {
            type: String,
        },
        domainName: {
            type: String,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as PropType<Scorecard.SqrScoreDetails>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    components: {
        Text,
        Spacer,
        DonutChart,
        oTable,
        Money,
        Percent,
        Roas,
        NumberVue,
        ScorecardSection,
        Skeleton,
    },
    setup(props) {
        const isPdf = props.mode === 'pdf'
        const sectionName = 'Search Term Coverage'

        const textOptions = computed<TextOptions>(() => {
            return {
                0: [
                    `Few search queries that direct traffic to your site are matched by existing keywords. This presents a significant opportunity to add new, relevant keywords and ${
                        props.isUsingCpa ? 'reduce your overall CPA' : 'increase your overall ROAS'
                    }.`,
                    `Use negative keywords to prevent irrelevant search queries from sending traffic to your ads, add new exact match keywords to target relevant traffic without wasting budget, and reduce reliance on broad match keywords where possible.`,
                    `With improved keyword management, you should expect to see significant performance improvements over the coming months.`,
                ],
                25: [
                    `Some search queries directing traffic to your site are matched by existing keywords, however some users may be seeing your ads when they are less-than-relevant to their search. By focusing on keywords appearing in the search term report, you can start to meaningfully improve ${
                        props.isUsingCpa ? 'CPA' : 'ROAS'
                    } and reduce wasted spend.`,
                    `In the near future, focus on adding exact and negative match keywords to mirror the searches triggering your ads without introducing the potential waste incurred by broad keywords.`,
                ],
                70: [
                    `Most of the search queries directing traffic to your website are matched by existing keywords. This means that searchers who see your ads are likely to find them relevant, and by extension more likely to convert into paying customers.`,
                    `Search term coverage isn’t an area of immediate concern—but building keyword lists is a continuous process. Keep an eye on the search term report for any necessary keyword additions.`,
                ],
            }
        })

        const _getSectionCopy = computed((): string[] => {
            return getSectionCopy(textOptions.value, props.score ?? 0) ?? []
        })

        const formattedTableHeaders = computed(
            (): {
                key: string
                text: string
            }[] => {
                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          { key: 'conversions', text: 'Conv.', width: 78 },
                          { key: 'cpa', text: 'CPA', width: 78 },
                      ]
                    : [
                          { key: 'conversionsValue', text: 'Value', width: 82 },
                          { key: 'roas', text: 'ROAS', width: 78 },
                      ]

                return [
                    { key: 'match', text: 'Status' },
                    { key: 'cost', text: 'Cost', width: 82 },
                    ...performanceSpecificHeaders,
                    { key: 'vsAvg', text: 'vs Avg.', width: 96 },
                ]
            }
        )

        const formattedTableItems = computed(() => {
            if (!props.details?.table_data) return []

            const totalCost = sumBy(props.details.table_data, r => r.metrics.cost ?? 0)
            const totalConv = sumBy(props.details.table_data, r => r.metrics.conversions ?? 0)
            const totalConvValue = sumBy(
                props.details.table_data,
                r => r.metrics.conversions_value ?? 0
            )

            const avgCpa = totalCost / totalConv
            const avgRoas = totalConvValue / totalCost

            return props.details.table_data.map((tableItem, index) => {
                const { conversions, conversions_value: conversionsValue, cost } = tableItem.metrics

                const cpa = cost / conversions || 0
                const roas = conversionsValue / cost || 0

                const vsAvg = (props.isUsingCpa ? cpa / avgCpa || 1 : roas / avgRoas || 1) - 1

                return {
                    id: index,
                    status: tableItem.name,
                    vsAvg: vsAvg,
                    match: tableItem.name,
                    cost,
                    conversions,
                    cpa,
                    conversionsValue,
                    roas,
                }
            })
        })

        const searchTermVisTableHeaders = computed(() => [
            { key: 'type', text: 'Type' },
            { key: 'cost', text: 'Cost', width: 92 },
            { key: 'costPercent', text: 'Cost (%)', width: 88 },
            ...(props.isUsingCpa
                ? [
                      { key: 'conversions', text: 'Conv.', width: 76 },
                      { key: 'cpa', text: 'CPA', width: 88 },
                  ]
                : [
                      { key: 'conversionsValue', text: 'Value', width: 88 },
                      { key: 'roas', text: 'ROAS', width: 96 },
                  ]),
        ])

        const searchTermVisTableItems = computed(() => {
            return props.details?.sub_table_data?.map((tableItem, index) => {
                const { conversions, conversions_value: conversionsValue, cost } = tableItem.metrics
                const cpa = conversions ? (cost ?? 0) / conversions : 0
                const roas = conversionsValue ? conversionsValue / (cost ?? 0) : 0

                return {
                    id: index,
                    type: tableItem.name,
                    cost,
                    costPercent:
                        cost / sumBy(props.details?.sub_table_data ?? [], r => r.metrics.cost),
                    conversions,
                    cpa,
                    conversionsValue,
                    roas,
                }
            })
        })

        return {
            sectionType: Scorecard.SectionTypes.sqr,
            sectionName: Scorecard.SectionNames.sqr,
            searchTermVisTableHeaders,
            searchTermVisTableItems,
            textOptions,
            formatDonutChartData,
            getSectionCopy: _getSectionCopy,
            formattedTableHeaders,
            formattedTableItems,
            isPdf,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.data-container {
    @include container;
    @include br-20;
    overflow: hidden;

    & .table {
        border-top: 1px solid;
        @include opteo-border;
        margin-top: 32px;
    }
}

:deep(.improvement-note) {
    @include br-20;
}
</style>
