import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AdjustDeviceBidsV2, Entity } from '@opteo/types'
import { useRoas } from '@opteo/components-next'
import {
    calculateCpa,
    calculateRoas,
    instanceOfAdGroup,
    instanceOfCampaign,
    instanceOfCampaignGroup,
} from './utils'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'
import {
    extractIdFromResourceName,
    formatEntityPillLink,
    formatEntityPillLinkList,
} from '@/lib/globalUtils'

export function useEntityInfo(body: AdjustDeviceBidsV2.DeviceBidsRecActions, isUsingCpa: boolean) {
    const campaign = instanceOfCampaign(body)
        ? { campaign: body.campaign, campaign_metrics: body.campaign_metrics }
        : {}

    const adGroup = instanceOfAdGroup(body)
        ? {
              ad_group: body.ad_group,
              campaign: body.campaign,
              ad_group_metrics: body.ad_group_metrics,
          }
        : {}

    const campaignGroup = instanceOfCampaignGroup(body)
        ? {
              group_metrics: body.group_metrics,
              campaigns: body.campaigns,
              campaigns_metrics: body.campaigns_metrics,
          }
        : {}

    const entity: 'adgroup' | 'campaign' | 'campaign-group' = Object.keys(adGroup).length
        ? 'adgroup'
        : Object.keys(campaign).length
          ? 'campaign'
          : 'campaign-group'

    const entityLabel =
        entity === 'adgroup' ? 'ad group' : entity === 'campaign' ? 'campaign' : 'campaign group'

    const entityName =
        entity === 'adgroup' && adGroup
            ? (adGroup.ad_group?.name as string)
            : entity === 'campaign' && campaign
              ? (campaign.campaign?.name as string)
              : body.campaign_group.campaign_group_name

    const entityPillList =
        entity === 'campaign-group' && campaignGroup
            ? campaignGroup.campaigns?.map(campaign => {
                  return { content: campaign.name, type: 'campaign' }
              })
            : []

    const deepLinkParams =
        entity === 'adgroup' && adGroup
            ? {
                  adGroupId: adGroup.ad_group?.id ?? 0,
                  campaignId: adGroup.campaign?.id ?? 0,
              }
            : entity === 'campaign' && campaign
              ? {
                    campaignId: campaign.campaign?.id ?? 0,
                }
              : null

    const entityPillLinkProps: EntityPillLinkProps<ValidEntity> = formatEntityPillLink<ValidEntity>(
        {
            entityPillData: {
                type: entity,
                content: entityName,
            },
            deepLinkParams,
        }
    )

    /* Entity Pill Links only exist at the campaign group level */
    const entityPillLinkListEntities =
        entity === 'campaign-group' && campaignGroup
            ? campaignGroup.campaigns?.map(campaign => {
                  return formatEntityPillLink<ValidEntity>({
                      entityPillData: {
                          type: 'campaign',
                          content: campaign.name,
                      },
                      deepLinkParams: {
                          campaignId: extractIdFromResourceName(
                              campaign.resource_name,
                              Entity.EntityLocation.Campaign
                          ),
                      },
                  })
              }) ?? []
            : []

    const entityPillLinkListItems = formatEntityPillLinkList({
        entities: entityPillLinkListEntities,
        sortByEntities: ['campaign'],
    })

    const calculatePerformanceMetric = (
        cost: number,
        conversions: number,
        conversionsValue: number
    ) => {
        if (isUsingCpa) return calculateCpa(cost, conversions)
        return calculateRoas(cost, conversionsValue)
    }

    const adgroupPerformanceMetric = calculatePerformanceMetric(
        adGroup.ad_group_metrics?.cost ?? 0,
        adGroup.ad_group_metrics?.all_conversions ?? 0,
        adGroup.ad_group_metrics?.all_conversions_value ?? 0
    )

    const campaignPerformanceMetric = calculatePerformanceMetric(
        campaign.campaign_metrics?.cost ?? 0,
        campaign.campaign_metrics?.all_conversions ?? 0,
        campaign.campaign_metrics?.all_conversions_value ?? 0
    )

    const campaignsPerformanceMetric = calculatePerformanceMetric(
        campaignGroup.campaigns_metrics?.cost ?? 0,
        campaignGroup.campaigns_metrics?.all_conversions ?? 0,
        campaignGroup.campaigns_metrics?.all_conversions_value ?? 0
    )

    /*
        The average CPA or ROAS for the entity
    */
    const entityAverage =
        entity === 'adgroup'
            ? adgroupPerformanceMetric
            : entity === 'campaign'
              ? campaignPerformanceMetric
              : campaignsPerformanceMetric

    const formattedEntityAverage = isUsingCpa
        ? useDomainMoney({ value: entityAverage }).value.displayValue.value
        : useRoas({ value: entityAverage }).displayValue.value

    return {
        entity,
        entityLabel,
        entityName,
        entityAverage,
        entityPillList,
        entityPillLinkProps,
        entityPillLinkListItems,
        campaignGroup,
        formattedEntityAverage,
    }
}
