<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :invalid-reason="invalidReason"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
    >
        <template #content>
            <div v-for="copy in getSectionCopy" :key="copy">
                <Text as="p" size="f-7" v-html="copy" />
                <Spacer height="1rem" />
            </div>

            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">Spend vs. Budget</Text>
            <Spacer height="2rem" />

            <div class="line-chart-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <Spacer height="0.5rem" />
                <div class="line-chart">
                    <LineChart
                        v-if="details?.series"
                        :show-keys="true"
                        :series="details?.series ?? []"
                        :metric="{
                            label: 'cost',
                            currency: domainCurrency,
                            dataType: 'money',
                        }"
                        :show-zero="true"
                        :horizontal-markers="horizontalMarker"
                        :chartHeight="200"
                        :remove-placeholder-container="true"
                    >
                        <template #tooltip="{ hoveredItem }">
                            <ChartTooltip
                                v-if="hoveredItem"
                                :plot-lines="generateTooltipPlotlines(hoveredItem)"
                            />
                        </template>
                        <template #markerText>
                            <ColorTag
                                color="red"
                                title="Monthly Budget"
                                :content="formattedBudget"
                            />
                        </template>
                    </LineChart>
                </div>
            </div>
            <Spacer height="1.5rem" />
            <ImprovementNote>
                <Text size="f-9" color="gray">
                    This projection is based on spend per weekday since the beginning of this
                    calendar month. It does not consider daily campaign budgets set in Google Ads
                    when evaluating the likelihood of missing target.
                </Text>
            </ImprovementNote>

            <Spacer height="2.5rem" />

            <Text as="h6" weight="600">Key Statistics</Text>
            <Spacer height="2rem" />
            <div class="key-statistics-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="statistic in keyStatistics" class="key-statistic-item">
                    <Text as="span" size="f-8">{{ statistic.name }}</Text>
                    <Spacer height="0.375rem" />

                    <!-- No budget set-->
                    <div v-if="statistic.value === 'No Budget'" class="no-budget">
                        <Text as="h5" weight="600" color="gray" style="letter-spacing: -0.06rem">
                            {{ statistic.value }}
                        </Text>
                        <Spacer width="0.5rem" />
                        <Tooltip
                            maxWidth="320px"
                            content="Click the <b>Update Budget</b> button at the top right of this slide to set a monhtly budget and recalculate statistics."
                        >
                            <InfoIcon style="transform: translateY(2px)" />
                        </Tooltip>
                    </div>

                    <!-- Has budget set -->
                    <Text v-else as="h5" weight="600" style="letter-spacing: -0.06rem">
                        {{ statistic.value }}
                    </Text>
                </div>
            </div>
        </template>
    </ScorecardSection>
</template>

<script lang="ts">
// external
import { PropType, computed, defineComponent, Ref } from 'vue'
import subMonths from 'date-fns/subMonths'
import formatDate from 'date-fns/format'
import add from 'date-fns/esm/add/index'
import findIndex from 'lodash-es/findIndex'

// local
import { TextOptions, getSectionCopy } from './utils'
import ScorecardSection from './ScorecardSection.vue'
import { Scorecard } from '@opteo/types'
import Skeleton from '@/components/util/Skeleton.vue'

import {
    Text,
    TextColor,
    Spacer,
    LineChart,
    ChartTooltip,
    HorizontalMarker,
    ChartMetric,
    useMoney,
    ColorTag,
    Tooltip,
    InfoIcon,
} from '@opteo/components-next'

export default defineComponent({
    name: 'ProjectedSpend',
    components: {
        Text,
        Spacer,
        LineChart,
        ChartTooltip,
        ScorecardSection,
        Tooltip,
        InfoIcon,
        Skeleton,
        ColorTag,
    },
    props: {
        score: {
            type: Number,
        },
        invalid: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
        },
        domainName: {
            type: String,
        },
        domainCurrency: {
            type: String,
        },
        details: {
            type: Object as PropType<Scorecard.ProjectedSpendScoreDetails>,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    emits: ['open-budget-modal'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'

        const hasBudgetSet = computed(() => !!props.details?.budget)

        const invalidReason = computed(() => {
            const isInvalidDueToNotBudgetSet =
                props?.invalid && props.details?.spend_status === 'none' && !hasBudgetSet.value

            if (isInvalidDueToNotBudgetSet) {
                return `Please set a monthly budget to see data for this section`
            }
        })

        const keyStatistics = computed(() => [
            {
                name: 'Spend to Date',
                value: useMoney({
                    value: props.details?.key_stats.spend_to_date ?? 0,
                    currency: props.domainCurrency,
                }).displayValue.value,
            },
            {
                name: 'Projected Spend',
                value: useMoney({
                    value: props.details?.key_stats.projected_spend ?? 0,
                    currency: props.domainCurrency,
                }).displayValue.value,
            },
            {
                name: 'Monthly Budget',
                value: hasBudgetSet
                    ? useMoney({
                          value: props.details?.key_stats.monthly_budget ?? 0,
                          currency: props.domainCurrency,
                      }).displayValue.value
                    : 'No Budget',
            },
            {
                name: 'Remaining Budget',
                value: hasBudgetSet
                    ? useMoney({
                          value: props.details?.key_stats.remaining_budget ?? 0,
                          currency: props.domainCurrency,
                      }).displayValue.value
                    : 'No Budget',
            },
            {
                name: 'Average Daily Spend',
                value: useMoney({
                    value: props.details?.key_stats?.average_daily_spend ?? 0,
                    currency: props.domainCurrency,
                }).displayValue.value,
            },
            {
                name: 'Suggested Daily Budget',
                value: hasBudgetSet
                    ? useMoney({
                          value: props.details?.key_stats.suggested_daily_budget ?? 0,
                          currency: props.domainCurrency,
                      }).displayValue.value
                    : 'No Budget',
            },
        ])

        const generateTooltipPlotlines = (
            hoveredItems: {
                displayYValue: Ref<string>
                x: Date
                dotted: boolean
                seriesName: string
                y: number
                color: string
            }[]
        ) => {
            if (!hoveredItems) return []

            const tooltips: ChartMetric[] = []
            hoveredItems.forEach((item, index) => {
                if (!item) return

                // default label (for current month)
                let label = formatDate(new Date(item.x), 'do MMM')
                if (item.seriesName === 'Previous Month') {
                    /*
                     * if we are hovering on a working tooltip from the chart, items should always exist
                     * grabbing the first item which will be the 1st of the month
                     * subtracting a month as the series data for previous is one month ahead
                     */
                    const dateToStartOn = subMonths(
                        new Date(props.details?.series[0].items[0].x!),
                        1
                    )
                    // grab the index of the item of the tooltip we are currently hovered on
                    const indexOfTooltip = findIndex(
                        props.details?.series[0].items!,
                        seriesItem => seriesItem.y === item.y
                    )
                    // add the index as days to our initial starting point
                    label = formatDate(add(dateToStartOn, { days: indexOfTooltip }), 'do MMM')
                }

                const value = item.displayYValue.value
                const dotted = item.dotted
                const color = item.color as TextColor

                tooltips.push({ label, value, dotted, color })
            })

            return tooltips
        }

        const textOptions = computed<TextOptions>(() => {
            const projectedSpendWithCurrency = useMoney({
                value: props.details?.projected_final_day_cost ?? 0,
                currency: props.domainCurrency,
            }).displayValue.value

            const underSpendWithCurrency = useMoney({
                value: underspend.value ?? 0,
                currency: props.domainCurrency,
            }).displayValue.value

            if (props.details?.spend_status === 'below') {
                return {
                    0: [
                        `Your account should underspend by <b>${underSpendWithCurrency}</b> this month.`,
                        `Spending seems overly cautious when considering your monthly budget. Your ads could be capturing many more impressions and conversions if monthly spend more closely matched your budget. Consider expanding with more ads, targeting new audiences to broaden reach, and adjusting bids strategically to capture more high-value traffic. These steps should help align spending with your budget and scale performance without overspending.`,
                    ],
                    50: [
                        `Your account should underspend by <b>${underSpendWithCurrency}</b> this month.`,
                        `Spending seems overly cautious when considering your monthly budget. Your ads could be capturing many more impressions and conversions if monthly spend more closely matched your budget. Consider expanding with more ads, targeting new audiences to broaden reach, and adjusting bids strategically to capture more high-value traffic. These steps should help align spending with your budget and scale performance without overspending.`,
                    ],
                    70: [
                        `Your account is on track to hit—or come very close to hitting—budget this month. To maintain this momentum, focus on keeping spend levels consistent while searching for any opportunities to improve allocation efficiency. This could include chanelling spend toward higher-performing campaigns, refining bids, improving audience targeting, or optimising assets for better engagement. These strategies can help maximise conversions within your existing budget, and set the stage for sustained growth.`,
                    ],
                }
            } else if (props.details?.spend_status === 'above') {
                return {
                    0: [
                        `Your account is looking at a significant overspend this month.`,
                        `To address this issue, focus on reducing wasted spend wherever possible. Pause underperforming keywords, make strategic bid adjustments, and reduce campaign budgets—trimming as much as possible without impacting your core campaigns. These steps should help bring spending back in line with your monthly budget while minimizing the potential negative effects of cost-cutting.`,
                    ],
                    50: [
                        `Your account is looking at a meaningful overspend this month.`,
                        `To address this issue, focus on reducing wasted spend wherever possible. Pause underperforming keywords, make strategic bid adjustments, and reduce campaign budgets—trimming as much as possible without impacting your core campaigns. These steps should help bring spending back in line with your monthly budget while minimizing the potential negative effects of cost-cutting.`,
                    ],
                    70: [
                        `Your account is on track to hit—or come very close to hitting—budget this month. To maintain this momentum, focus on keeping spend levels consistent while searching for any opportunities to improve allocation efficiency. This could include chanelling spend toward higher-performing campaigns, refining bids, improving audience targeting, or optimising assets for better engagement. These strategies can help maximise conversions within your existing budget, and set the stage for sustained growth.`,
                    ],
                }
            } else if (props.details?.spend_status === 'on') {
                return {
                    70: [
                        `Your account is on track to hit—or come very close to hitting—budget this month. To maintain this momentum, focus on keeping spend levels consistent while searching for any opportunities to improve allocation efficiency. This could include chanelling spend toward higher-performing campaigns, refining bids, improving audience targeting, or optimising assets for better engagement. These strategies can help maximise conversions within your existing budget, and set the stage for sustained growth.`,
                    ],
                }
            }
            return {
                0: [
                    `Your account does not have a monthly budget set. Based on your spending so far this month, projected spend is displayed on the line chart below. By the end of this calendar month, your total spend should reach around <b>${projectedSpendWithCurrency}</b>.`,
                ],
            }
        })

        const underspend = computed(() => {
            return Math.abs(
                (props.details?.projected_final_day_cost ?? 0) - (props.details?.budget ?? 0)
            )
        })
        const _getSectionCopy = computed(() => {
            return getSectionCopy(textOptions.value, props.score ?? 0)
        })
        const horizontalMarker = computed<HorizontalMarker[]>(() => {
            return props.details?.budget
                ? [{ label: 'Monthly Budget', value: props.details?.budget, color: 'red' }]
                : []
        })
        const formattedBudget = computed(() => {
            return useMoney({ value: props.details?.budget ?? 0, currency: props.domainCurrency })
                .displayValue.value
        })

        const openBudgetModal = () => emit('open-budget-modal')

        return {
            sectionType: Scorecard.SectionTypes.projectedSpend,
            sectionName: Scorecard.SectionNames.projectedSpend,
            isPdf,
            invalidReason,
            hasBudgetSet,
            keyStatistics,
            generateTooltipPlotlines,
            underspend,
            getSectionCopy: _getSectionCopy,
            horizontalMarker,
            formattedBudget,
            openBudgetModal,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.line-chart-container {
    @include container;
    @include br-20;
    @include pa-32;
    overflow: auto;
}

.key-statistics-container {
    @include container;
    @include br-20;
    @include w-100;
    @include grid;
    grid-template-columns: 1fr 1fr;
}
.key-statistic-item {
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid #f7f7f9;
}
.key-statistic-item:nth-child(odd) {
    border-right: 1px solid #f7f7f9;
}
.key-statistic-item:nth-last-child(-n + 2) {
    border-bottom: none;
}

.key-statistic-item .no-budget {
    @include flex;
    @include items-center;
}
.key-statistic-item .skeleton-container {
    height: 24px;
    @include flex;
    @include items-center;
}
:deep(.improvement-note) {
    @include br-20;
}

@media screen and (max-width: $mq-767-max) {
    .key-statistics-container {
        @include block;
    }
    .key-statistic-item,
    .key-statistic-item:nth-last-child(-n + 2) {
        @include ph-24;
        @include pv-20;
        border-bottom: 1px solid #f7f7f9;
    }
    .key-statistic-item:last-child {
        border-bottom: none;
    }
    .key-statistic-item:nth-child(odd) {
        border-right: unset;
    }
}
</style>
