<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        section-type="searchTermAlignment"
        section-name="Search Term Alignment"
        :mode="mode"
        @score-history-button-clicked="emit('scoreHistoryButtonClicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1.5rem" />

            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Alignment Summary</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openBreakdown('one')"
                    :loading="breakdownLoading && breakdownButtonClickedName === 'one'"
                    color="white"
                    size="small"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />
            <div class="sub-scores-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <AlignmentChart
                    :items="alignmentChartScores"
                    :landing-page-scan-failed="landingPageScanFailed"
                />

                <div v-for="(row, index) in alignmentSubScores" class="item">
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="
                                isLoading ||
                                row.score === null ||
                                (index > 0 && landingPageScanFailed)
                            "
                            :score="
                                isLoading ||
                                row.score === null ||
                                (index > 0 && landingPageScanFailed)
                                    ? 0
                                    : row.score
                            "
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <div style="display: flex">
                            <InlineAlignmentOverlap
                                :disabled="
                                    isLoading ||
                                    row.score === null ||
                                    (index > 0 && landingPageScanFailed)
                                "
                                :left="row.leftItem"
                                :right="row.rightItem"
                            />
                            <Tooltip
                                v-if="index > 0 && landingPageScanFailed && mode === 'app'"
                                content="If this problem persists, please contact support."
                                :max-width="154"
                            >
                                <InfoIcon
                                    class="tooltip-info-icon"
                                    style="margin-left: 0.375rem; transform: translateY(0.25rem)"
                                />
                            </Tooltip>
                        </div>
                        <Spacer height="0.25rem" />
                        <Text
                            as="p"
                            size="f-8"
                            :color="
                                row.score === null || (index > 0 && landingPageScanFailed)
                                    ? 'gray'
                                    : 'black'
                            "
                        >
                            {{ row.copy }}
                        </Text>
                    </div>
                </div>
            </div>

            <Spacer height="2rem" />

            <div class="flex items-center justify-between">
                <Text as="h6" weight="600"> Top Misaligned Ad Groups</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openBreakdown('two')"
                    :loading="breakdownLoading && breakdownButtonClickedName === 'two'"
                    color="white"
                    size="small"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />
            <oTable
                class="table"
                :headers="topMisalignedAdGroupHeaders"
                :items="topMisalignedAdGroupItems"
                :fixed-layout="true"
                :border-radius="20"
                responsive-mode="columns"
                responsive-breakpoint="767px"
                :class="{ 'pdf-shadow-fix': isPdf }"
            >
                <template #header.adGroup>
                    <div class="header-cell">Ad Group</div>
                </template>
                <template #column.adGroup="cellData">
                    <div class="column-overlay">
                        <LocationCellPopout
                            :entity-name="cellData.value"
                            entity-type="adgroup"
                            :campaign="cellData.row.campaign"
                        >
                            <EntityPill type="adgroup" :content="cellData.value" :tooltip="false" />
                        </LocationCellPopout>
                    </div>
                </template>
                <template #column.alignment="cellData">
                    <Popout
                        :offset="[-34, 18]"
                        :interactive="false"
                        :borderRadius="32"
                        :z-index="2147483005"
                        trigger="mouseenter"
                        placement="top"
                        max-width="28rem"
                        :popperOptions="{
                            strategy: 'fixed',
                        }"
                    >
                        <ScorePill
                            :key="cellData.value"
                            :score="cellData.value"
                            data-type="percentage"
                        />
                        <template #content>
                            <ScoreList
                                :items="mapAlignmentsToPopout(cellData.row.subScores)"
                                score-mode="pill"
                                label-mode="overlap"
                                :remove-border="true"
                            />
                        </template>
                    </Popout>
                </template>
                <template #column.action="cellData">
                    <div class="download-button">
                        <Tooltip
                            :content="
                                mode !== 'pdf'
                                    ? 'Download full analysis of search term, RSA, and landing page alignment.'
                                    : ''
                            "
                            :offset="[0, 20]"
                            :max-width="234"
                            :delay="[1000, 0]"
                        >
                            <oButton
                                @clicked="triggerAdGroupAnalysisDownload(cellData.value)"
                                :disabled="mode === 'pdf'"
                                color="white"
                                size="small"
                                :class="{ 'pdf-shadow-fix': isPdf }"
                            >
                                Download Analysis
                            </oButton>
                        </Tooltip>
                    </div>
                </template>
            </oTable>

            <Spacer height="1.5rem" />
            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Top misaligned ad groups weighted by impressions. Includes enabled Search
                    campaigns only. Data collected over the last <b>30 days</b>.
                </Text>
            </ImprovementNote>

            <!-- Campaign Breakdown Panel -->
            <BreakdownPanel
                @breakdown-requested="emit('breakdownRequested')"
                :show-panel="showBreakdown"
                :close-panel="closeUrlBreakdown"
                title="Alignment Summary"
                :loading="breakdownLoading"
                :width="1094"
            >
                <template #title>
                    <ColorTag color="blue" title="Campaign Breakdown" />
                </template>
                <template #content>
                    <div
                        v-for="(campaign, index) in filteredBreakdownTableItems"
                        :class="[
                            'campaign-breakdown-container',
                            {
                                'mb-36':
                                    filteredBreakdownTableItems &&
                                    index !== filteredBreakdownTableItems.length - 1,
                            },
                        ]"
                    >
                        <BreakdownTableHeader
                            entity-type="campaign"
                            :entity-label="campaign.name"
                            :currency="domainCurrency"
                            :cost="campaign.metrics.cost"
                        />

                        <Spacer height="2.25rem" />
                        <oTable
                            :headers="breakdownTableHeaders"
                            :items="campaign.adGroups"
                            :fixed-layout="true"
                            :border-radius="20"
                            :class="{ 'pdf-shadow-fix': isPdf }"
                            :per-page="4"
                            :persist-height="true"
                            order-by="cost"
                            order="DESC"
                        >
                            <template #header.name>
                                <div class="header-cell">Ad Group</div>
                            </template>
                            <template #header.searchTermWordsMissingInRsa>
                                <div>Missing in RSAs</div>
                                <Tooltip
                                    content="Showing the top five search term words missing in RSA copy, ordered by impressions."
                                    :max-width="190"
                                >
                                    <InfoIcon
                                        class="tooltip-info-icon"
                                        style="
                                            margin-left: 0.375rem;
                                            transform: translateY(0.125rem);
                                        "
                                    />
                                </Tooltip>
                            </template>
                            <template #header.searchTermWordsMissingInLp>
                                <div>Missing in LPs</div>
                                <Tooltip
                                    content="Showing the top five search term words missing in landing page copy, ordered by impressions."
                                    :max-width="206"
                                >
                                    <InfoIcon
                                        class="tooltip-info-icon"
                                        style="
                                            margin-left: 0.375rem;
                                            transform: translateY(0.125rem);
                                        "
                                    />
                                </Tooltip>
                            </template>
                            <template #column.name="cellData">
                                <div class="column-overlay">
                                    <Tooltip
                                        :content="cellData.value.length > 16 ? cellData.value : ''"
                                        :offset="[0, 8]"
                                        placement="top-start"
                                        :max-width="1060"
                                    >
                                        <EntityPill
                                            type="adgroup"
                                            :content="cellData.value"
                                            :tooltip="false"
                                        />
                                    </Tooltip>
                                </div>
                            </template>
                            <template #column.alignment="cellData">
                                <Popout
                                    :offset="[-34, 18]"
                                    :interactive="false"
                                    :borderRadius="32"
                                    :z-index="2147483005"
                                    trigger="mouseenter"
                                    placement="top"
                                    max-width="28rem"
                                    :popperOptions="{
                                        strategy: 'fixed',
                                    }"
                                >
                                    <ScorePill
                                        :key="cellData.value"
                                        :score="cellData.value"
                                        data-type="percentage"
                                    />
                                    <template #content>
                                        <ScoreList
                                            :items="mapAlignmentsToPopout(cellData.row)"
                                            score-mode="pill"
                                            label-mode="overlap"
                                            :remove-border="true"
                                        />
                                    </template>
                                </Popout>
                            </template>
                            <template #column.cost="cellData">
                                <Money :currency="domainCurrency" :value="cellData.value" />
                            </template>
                            <template #column.conversions="cellData">
                                <NumberVue :value="cellData.value" :decimal-places="1" />
                            </template>
                            <template #column.conversionsValue="cellData">
                                <Money :currency="domainCurrency" :value="cellData.value" />
                            </template>
                            <template #column.cpa="cellData">
                                <Money :currency="domainCurrency" :value="cellData.value" />
                            </template>
                            <template #column.roas="cellData">
                                <Roas :value="cellData.value" />
                            </template>
                            <template #column.action="cellData">
                                <div class="download-button">
                                    <Tooltip
                                        :content="
                                            mode !== 'pdf'
                                                ? 'Download full analysis of search term, RSA, and landing page alignment.'
                                                : ''
                                        "
                                        :offset="[0, 20]"
                                        :max-width="234"
                                        :delay="[1000, 0]"
                                    >
                                        <oButton
                                            @clicked="
                                                triggerAdGroupAnalysisDownload(cellData.value)
                                            "
                                            :disabled="mode === 'pdf'"
                                            color="white"
                                            size="small"
                                        >
                                            Download Analysis
                                        </oButton>
                                    </Tooltip>
                                </div>
                            </template>
                        </oTable>
                    </div>
                    <!-- Show More Button -->
                    <div v-if="!showMoreButtonDisabled" class="panel-button-container">
                        <oButton
                            color="white"
                            size="medium"
                            @clicked="showMoreTables"
                            :disabled="showMoreButtonDisabled"
                        >
                            Show More
                        </oButton>
                    </div>
                </template>
            </BreakdownPanel>
        </template>
    </ScorecardSection>
</template>
<script lang="ts" setup>
import { Scorecard } from '@opteo/types'
import ScorecardSection from './ScorecardSection.vue'
import { computed, Ref, ref, watch } from 'vue'
import { getSectionCopy, formatBreakdown } from './utils'
import useMediaQuery from '@/composition/global/useMediaQuery'
import orderBy from 'lodash-es/orderBy'
import { downloadCsv } from '@/lib/globalUtils'
import BreakdownTableHeader from './BreakdownTableHeader.vue'
import AbsentNGramCard from './layouts/AbsentNGramCard.vue'
import InfoIcon from '@/components/global/InfoIcon.vue'
import AlignmentChart from './AlignmentChart.vue'
import ScoreList from './layouts/ScoreList.vue'
import ScorePill from '@/components/scorecard/ScorePill.vue'
import InlineAlignmentOverlap from './InlineAlignmentOverlap.vue'
import LocationCellPopout from './LocationCellPopout.vue'
import BreakdownPanel from './BreakdownPanel.vue'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Roas,
    Number as NumberVue,
    oButton,
    Tooltip,
    EntityPill,
    ColorTag,
    Popout,
} from '@opteo/components-next'
import keyBy from 'lodash-es/keyBy'
import flatMap from 'lodash-es/flatMap'
import groupBy from 'lodash-es/groupBy'
import mapValues from 'lodash-es/mapValues'
import sumBy from 'lodash-es/sumBy'
import { adjustWidthsToFitText, downloadExcelFile } from '@/lib/globalUtils/downloadExcelFile'
import ExcelJS from 'exceljs'
import { map, sortBy, uniq } from 'lodash-es'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { decompressFromBase64 } from 'lz-string'

import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'
import ImprovementNote from '@/components/improvement/ImprovementNote.vue'

const props = defineProps<{
    score: number
    invalid: boolean
    isLoading: boolean
    domainName: string
    isUsingCpa: boolean
    domainCurrency: string
    mode: 'live' | 'pdf' | 'app'
    details: Scorecard.SearchTermAlignmentSection['details']
    breakdown?: Scorecard.SearchTermAlignmentSection['breakdown']
    breakdownLoading?: boolean
}>()

const emit = defineEmits<{
    scoreHistoryButtonClicked: []
    breakdownRequested: []
}>()

const isPdf = props.mode === 'pdf'
const { aboveiPadLandscape } = useMediaQuery()
const textOptions = {
    0: [
        `Alignment between your search terms, RSA copy, and landing page copy ensures your ads are relevant to user searches, which in turn helps to improve quality scores and reduce wasted spend.`,
        `This alignment between your written copy and user search intent makes it easier for searchers to find exactly what they're looking for, leading to higher click-through rates, lower bounce rates, more on-site engagement, and improved conversion rates.`,
        `Maintaining alignment can also help with creating a strong user experience that encourages repeat visits, reducing mismatched or unprofitable traffic, and building trust with your audience through clear, consistent, and intentional messaging.`,
    ],
}
const sectionCopy = computed(() => {
    return getSectionCopy(textOptions, props.score ?? 0)
})

// Top Misaligned Ad Groups.
const topMisalignedAdGroupHeaders = [
    { key: 'adGroup', text: 'Ad Group', noPadding: true },
    { key: 'alignment', text: 'Alignment', width: 90, noPadding: true },
]

if (!isPdf) {
    topMisalignedAdGroupHeaders.push({ key: 'action', text: 'Action', width: 150, noPadding: true })
}

const topMisalignedAdGroupItems = computed(() => {
    return props.details.worstAdGroups.map(item => {
        return {
            campaign: item.campaignName,
            adGroup: item.name,
            action: item.resourceName,
            alignment: item.alignment,
            subScores: {
                searchTermToRsaAlignment: item.searchTermToRsaAlignment,
                rsaToLandingPageAlignment: item.rsaToLandingPageAlignment,
                searchTermToLandingPageAlignment: item.searchTermToLandingPageAlignment,
            },
        }
    })
})

// Sub Scores
const searchTermRsaScore = computed(() => props.details.subScores.searchTermToRsaAlignment)
const rsaLandingPageScore = computed(() => props.details.subScores.rsaToLandingPageAlignment)
const searchTermLandingPageScore = computed(
    () => props.details.subScores.searchTermToLandingPageAlignment
)
const landingPageScanFailed = computed(() => props.details.landingPageScanFailed)

const entity = {
    searchTerm: { name: 'Search Terms', color: 'pink' },
    rsa: { name: 'RSA Copy', color: 'green' },
    landingPage: { name: 'Landing Page Copy', color: 'purple' },
}

/*
    Caution: the labels here are correct, despite the apparently-misaligned sides.
    This is because side relationships determine the length of the opposite side.
*/
const alignmentChartScores = {
    sideAB: { label: entity.landingPage.name, length: 101 - searchTermRsaScore.value }, // This score is the relationship between the search term and RSA
    sideBC: { label: entity.rsa.name, length: 101 - searchTermLandingPageScore.value }, // This score is the relationship between the  search term and LP
    sideCA: { label: entity.searchTerm.name, length: 101 - rsaLandingPageScore.value }, // This score is the relationship between the RSA and LP
}

const alignmentSubScores = [
    {
        leftItem: entity.searchTerm,
        rightItem: entity.rsa,
        copy:
            searchTermRsaScore.value >= 70
                ? 'Search term n-grams and RSA copy are well aligned, which should lead to cost-efficient click-through rates.'
                : 'Increasing search term and RSA copy alignment can help improve ad relevance and lead to higher CTRs.',
        score: searchTermRsaScore.value,
    },
    {
        leftItem: entity.rsa,
        rightItem: entity.landingPage,
        copy: landingPageScanFailed.value
            ? 'Unable to scan landing page content. This may be due to timeouts, restrictions, or other factors. If the issue persists, please contact support for assistance.'
            : rsaLandingPageScore.value >= 70
              ? 'Alignment between RSA copy and landing page copy can boost conversion rates and reduce bounce rates.'
              : `Increasing alignment between landing page copy and RSA copy can reduce bounce rates and help searchers find exactly what they're looking for on your site.`,
        score: rsaLandingPageScore.value,
    },
    {
        leftItem: entity.landingPage,
        rightItem: entity.searchTerm,
        copy: landingPageScanFailed.value
            ? 'Unable to scan landing page content. This may be due to timeouts, restrictions, or other factors. If the issue persists, please contact support for assistance.'
            : searchTermLandingPageScore.value >= 70
              ? 'Landing page copy that aligns closely with n-grams in search terms can significantly improve Quality Scores, boost relevance, and drive higher conversion rates.'
              : 'Increasing alignment between landing page copy and search terms can enhance overall user experience, raise Quality Scores, and boost conversion rates.',
        score: searchTermLandingPageScore.value,
    },
]

function mapAlignmentsToPopout(row) {
    const {
        searchTermToRsaAlignment,
        rsaToLandingPageAlignment,
        searchTermToLandingPageAlignment,
    } = row

    const popoutItems = [
        {
            text: {
                leftItem: entity.searchTerm,
                rightItem: entity.rsa,
            },
            scoreDonut: {
                score: searchTermToRsaAlignment,
                dataType: 'percentage',
                isPercentFinal: true,
                hidden:
                    searchTermToRsaAlignment === null ||
                    typeof searchTermToRsaAlignment === 'undefined'
                        ? true
                        : false,
            },
        },
        {
            text: {
                leftItem: entity.rsa,
                rightItem: entity.landingPage,
            },
            scoreDonut: {
                score: rsaToLandingPageAlignment,
                dataType: 'percentage',
                isPercentFinal: true,
                hidden:
                    landingPageScanFailed.value ||
                    searchTermToRsaAlignment === null ||
                    typeof searchTermToRsaAlignment === 'undefined'
                        ? true
                        : false,
            },
        },
        {
            text: {
                leftItem: entity.landingPage,
                rightItem: entity.searchTerm,
            },
            scoreDonut: {
                score: searchTermToLandingPageAlignment,
                dataType: 'percentage',
                isPercentFinal: true,
                hidden:
                    landingPageScanFailed.value ||
                    searchTermToRsaAlignment === null ||
                    typeof searchTermToRsaAlignment === 'undefined'
                        ? true
                        : false,
            },
        },
    ]

    return popoutItems
}

// // Breakdown Panel
const breakdownButtonClickedName = ref()

const showBreakdown = ref(false)

const openBreakdown = (buttonName: string) => {
    showBreakdown.value = true
    breakdownButtonClickedName.value = buttonName
}

const closeUrlBreakdown = () => {
    showBreakdown.value = false
    resetLimitTables()
    breakdownButtonClickedName.value = undefined
}

const breakdown = computed(() => {
    if (!props.breakdown) return undefined
    const decompressedString = decompressFromBase64(props.breakdown)
    return JSON.parse(decompressedString)
})

const breakdownTableHeaders = computed(() => {
    const performanceSpecificHeaders = props.isUsingCpa
        ? [
              { key: 'conversions', text: 'Conv.', width: 98, noPadding: true, sortable: true },
              { key: 'cpa', text: 'CPA', width: 100, noPadding: true, sortable: true },
          ]
        : [
              {
                  key: 'conversionsValue',
                  text: 'Value',
                  width: 98,
                  noPadding: true,
                  sortable: true,
              },
              { key: 'roas', text: 'ROAS', width: 100, noPadding: true, sortable: true },
          ]
    return [
        { key: 'name', text: 'Ad Group', noPadding: true, sortable: true },
        { key: 'alignment', text: 'Alignment', width: 118, noPadding: true, sortable: true },
        { key: 'cost', text: 'Cost', width: 98, noPadding: true, sortable: true },
        ...performanceSpecificHeaders,
        { key: 'action', text: 'Action', width: 158, noPadding: true },
    ]
})

// Show more tables button
let limitTables = ref(8)
function showMoreTables() {
    limitTables.value += 8
}
function resetLimitTables() {
    limitTables.value = 8
}

const breakdownItems = computed(() => {
    const formattedItems = (breakdown.value?.alignment ?? []).map(campaign => ({
        ...campaign,
        adGroups: campaign.adGroups.map(adGroup => ({
            ...adGroup,
            ...adGroup.metrics,
            action: adGroup.resourceName,
        })),
    }))
    return orderBy(formattedItems, ['metrics.cost'], ['desc'])
})

const filteredBreakdownTableItems = computed(() => {
    return breakdownItems.value.slice(0, limitTables.value) ?? []
})

const showMoreButtonDisabled = computed(() => {
    return breakdownItems?.value && limitTables.value >= breakdownItems.value.length
})

const adGroupDownloadInProgress: Ref<string | undefined> = ref()

const alignmentByAdGroup = computed(() => {
    const campaigns = breakdown.value?.alignment ?? []
    const adGroupAlignments = flatMap(campaigns, ({ adGroups }) => adGroups)
    return keyBy(adGroupAlignments, adGroup => adGroup.resourceName)
})

const landingPagesByNormalizedUrl = computed(() => {
    const landingPages = breakdown.value?.landingPages ?? []
    return keyBy(landingPages, ({ normalizedUrl }) => normalizedUrl)
})

const triggerAdGroupAnalysisDownload = (adGroupResourceName: string) => {
    const adgroup = alignmentByAdGroup.value[adGroupResourceName]

    if (!adgroup) {
        emit('breakdownRequested')
        adGroupDownloadInProgress.value = adGroupResourceName
        return
    }

    downloadAdGroupAnalysis(adgroup)
}

watch(alignmentByAdGroup, alignmentByAdGroupMap => {
    if (!adGroupDownloadInProgress.value) return

    const adGroupResourceName = adGroupDownloadInProgress.value
    const adgroup = alignmentByAdGroupMap[adGroupResourceName]

    downloadAdGroupAnalysis(adgroup)
    adGroupDownloadInProgress.value = undefined
})

const downloadAdGroupAnalysis = (adGroup: Scorecard.AdGroupAlignment) => {
    const workbook = new ExcelJS.Workbook()

    addStMissingInRsaSheet({ adGroup, workbook })
    addStMissingInLpSheet({ adGroup, workbook })
    addRsaMissingInLpSheet({ adGroup, workbook })
    addRsaMissingInStSheet({ adGroup, workbook })
    addLpMissingInRsaSheet({ adGroup, workbook })
    addLpMissingInStSheet({ adGroup, workbook })
    addSearchTermWordSheet({ adGroup, workbook })
    addRsaWordSheet({ adGroup, workbook })
    addLandingPageWordSheet({ adGroup, workbook })

    // apply global styles
    workbook.eachSheet(worksheet => {
        const headerRow = worksheet.getRow(1)
        headerRow.font = { ...headerRow.font, bold: true }
        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.font = { ...cell.font, name: 'Arial', family: 2, size: 10 }
                cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true }
            })
        })
        worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
    })

    const date = new Date()
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    const formatter = new Intl.DateTimeFormat('en-US', options)
    const formattedDate = formatter.format(date)

    downloadExcelFile({
        title: `Alignment Report (${formattedDate}) — ${props.domainName} → ${adGroup.campaignName} → ${adGroup.name}`,
        workbook,
    })
}

const addStMissingInRsaSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, searchTermWordsMissingInRsa } = adGroup

    const isUsingCpa = props.isUsingCpa

    const columns: Partial<ExcelJS.Column>[] = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'Search Term Word', key: 'word' },
        { header: 'Impressions', key: 'impressions' },
        { header: 'Cost', key: 'cost' },
        ...(isUsingCpa
            ? [{ header: 'Conversions', key: 'conversions' }]
            : [{ header: 'Conv. Value', key: 'conversionsValue' }]),
        { header: 'Search Terms', key: 'searchTerms' },
        { header: 'Missing in (RSA IDs)', key: 'rsaIds' },
    ]

    const rows = searchTermWordsMissingInRsa.map(item => ({
        campaignName,
        adGroupName,
        word: item.word,
        impressions: formatNumber(item.metrics.impressions),
        cost: formatNumber(item.metrics.cost),
        ...(isUsingCpa
            ? { conversions: formatNumber(item.metrics.conversions) }
            : { conversionsValue: formatNumber(item.metrics.conversionsValue) }),
        searchTerms: item.searchTermSet.join(',\n'),
        rsaIds: item.adIdSet.join(',\n'),
    }))

    const title = `Search Terms Missing In RSAs`
    const stMissingInRsaSheet = workbook.addWorksheet(title)

    stMissingInRsaSheet.columns = adjustWidthsToFitText({ columns, rows })
    stMissingInRsaSheet.addRows(rows)
}

const addStMissingInLpSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
    landingPageScanFailed: boolean
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, searchTermWordsMissingInLp } = adGroup

    const isUsingCpa = props.isUsingCpa

    const title = `Search Terms Missing in URLs`
    const stMissingInLpSheet = workbook.addWorksheet(title)

    const columns = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'Search Term Word', key: 'word' },
        { header: 'Impressions', key: 'impressions' },
        { header: 'Cost', key: 'cost' },
        ...(isUsingCpa
            ? [{ header: 'Conversions', key: 'conversions' }]
            : [{ header: 'Conv. Value', key: 'conversionsValue' }]),
        { header: 'Search Terms', key: 'searchTerms' },
        { header: 'Missing in (Landing Page URLs)', key: 'landingPages' },
        { header: 'Landing Page Scanned', key: 'landingPagesScannedOn' },
    ]

    let rows = []
    if (landingPageScanFailed.value) {
        rows = [
            {
                campaignName:
                    'Unable to scan landing page content. Scans can fail due timeouts, restrictions and many other reasons.',
            },
        ]
    } else {
        rows = searchTermWordsMissingInLp.map(item => {
            return {
                campaignName,
                adGroupName,
                word: item.word,
                impressions: formatNumber(item.metrics.impressions),
                cost: formatNumber(item.metrics.cost),
                ...(isUsingCpa
                    ? { conversions: formatNumber(item.metrics.conversions) }
                    : { conversionsValue: formatNumber(item.metrics.conversionsValue) }),
                searchTerms: item.searchTermSet.join(',\n'),
                landingPages: item.landingPageSet.join(',\n'),

                landingPagesScannedOn: getEarliestInspectionDate(item.landingPageSet),
            }
        })
    }

    stMissingInLpSheet.columns = adjustWidthsToFitText({ columns, rows })
    stMissingInLpSheet.addRows(rows)
}

const addRsaMissingInLpSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
    landingPageScanFailed: boolean
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, rsaWordsMissingInLp } = adGroup

    const title = `RSA Words Missing In URLs`
    const rsaMissingInLpSheet = workbook.addWorksheet(title)

    const columns = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'RSA Word', key: 'word' },
        { header: 'RSA Assets', key: 'rsaAssets' },
        { header: 'RSA IDs', key: 'rsaIds' },
        { header: 'Missing in (Landing Page URLs)', key: 'landingPages' },
        { header: 'Landing Page Scanned', key: 'landingPagesScannedOn' },
    ]

    let rows = []
    if (landingPageScanFailed.value) {
        rows = [
            {
                campaignName:
                    'Unable to scan landing page content. Scans can fail due timeouts, restrictions and many other reasons.',
            },
        ]
    } else {
        rows = sortBy(
            rsaWordsMissingInLp.map(item => ({
                campaignName,
                adGroupName,
                word: item.word,
                rsaAssets: item.assetSet.join(',\n'),
                rsaIds: item.adIdSet.join(',\n'),
                landingPages: item.landingPageSet.join(',\n'),
                landingPagesScannedOn: getEarliestInspectionDate(item.landingPageSet),
            })),
            ['word']
        )
    }

    rsaMissingInLpSheet.columns = adjustWidthsToFitText({ columns, rows })
    rsaMissingInLpSheet.addRows(rows)
}

const addRsaMissingInStSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, rsaWordsMissingInSt } = adGroup

    const columns = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'RSA Word', key: 'word' },
        { header: 'RSA Assets', key: 'rsaAssets' },
        { header: 'RSA IDs', key: 'rsaIds' },
    ]

    const rows = sortBy(
        rsaWordsMissingInSt.map(item => ({
            campaignName,
            adGroupName,
            word: item.word,
            rsaAssets: item.assetSet.join(',\n'),
            rsaIds: item.adIdSet.join(',\n'),
        })),
        ['word']
    )

    const title = `RSA Words Missing In Terms`
    const rsaMissingInStSheet = workbook.addWorksheet(title)

    rsaMissingInStSheet.columns = adjustWidthsToFitText({ columns, rows })
    rsaMissingInStSheet.addRows(rows)
}

const addLpMissingInRsaSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
    landingPageScanFailed: boolean
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, lpWordsMissingInRsa } = adGroup

    const title = `URL Words Missing In RSAs`
    const lpMissingInRsaSheet = workbook.addWorksheet(title)

    const columns = [
        { header: 'Landing Page URLs', key: 'landingPages' },
        { header: 'Landing Page Scanned', key: 'landingPagesScannedOn' },
        { header: 'Landing Page Word', key: 'word' },
        { header: 'Count', key: 'count' },
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'Missing in (RSA IDs)', key: 'rsaIds' },
    ]

    let rows = []
    if (landingPageScanFailed.value) {
        rows = [
            {
                landingPages:
                    'Unable to scan landing page content. Scans can fail due timeouts, restrictions and many other reasons.',
            },
        ]
    } else {
        rows = lpWordsMissingInRsa.map(item => ({
            landingPages: item.landingPageSet.join(',\n'),
            landingPagesScannedOn: getEarliestInspectionDate(item.landingPageSet),
            word: item.word,
            count: item.count.toString(),
            campaignName,
            adGroupName,
            rsaIds: item.adIdSet.join(',\n'),
        }))
    }

    lpMissingInRsaSheet.columns = adjustWidthsToFitText({ columns, rows })
    lpMissingInRsaSheet.addRows(rows)
}

const addLpMissingInStSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
    landingPageScanFailed: boolean
}) => {
    const { adGroup, workbook } = params

    const title = `URL Words Missing In Terms`
    const stMissingInLp = workbook.addWorksheet(title)

    const columns = [
        { header: 'Landing Page URLs', key: 'landingPages' },
        { header: 'Landing Page Scanned', key: 'landingPagesScannedOn' },
        { header: 'Landing Page Word', key: 'word' },
        { header: 'Count', key: 'count' },
    ]

    let rows = []
    if (landingPageScanFailed.value) {
        rows = [
            {
                landingPages:
                    'Unable to scan landing page content. Scans can fail due timeouts, restrictions and many other reasons.',
            },
        ]
    } else {
        rows = adGroup.lpWordsMissingInSt.map(item => ({
            landingPages: item.landingPageSet.join(',\n'),
            landingPagesScannedOn: getEarliestInspectionDate(item.landingPageSet),
            word: item.word,
            count: item.count.toString(),
        }))
    }

    stMissingInLp.columns = adjustWidthsToFitText({ columns, rows })
    stMissingInLp.addRows(rows)
}

const addSearchTermWordSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, ads } = adGroup

    const isUsingCpa = props.isUsingCpa

    const columns: Partial<ExcelJS.Column>[] = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'Search Term Word', key: 'word' },
        { header: 'Search Terms', key: 'searchTerms' },
        { header: 'Impressions', key: 'impressions' },
        { header: 'Cost', key: 'cost' },
        ...(isUsingCpa
            ? [{ header: 'Conversions', key: 'conversions' }]
            : [{ header: 'Conv. Value', key: 'conversionsValue' }]),
    ]

    const rawSearchTerms = flatMap(ads, ({ searchTerms }) => searchTerms)
    const wordSearchTerms = flatMap(rawSearchTerms, ({ searchTerm, words, metrics }) =>
        words.map(word => ({ word, metrics, searchTerm }))
    )

    const groupedByWord = groupBy(wordSearchTerms, ({ word }) => word)

    const summedMetricsByWord = map(groupedByWord, wordSearchTerms => {
        const word = wordSearchTerms[0].word

        const cost = sumBy(wordSearchTerms, ({ metrics: { cost } }) => cost)
        const impressions = sumBy(wordSearchTerms, ({ metrics: { impressions } }) => impressions)
        const conversions = sumBy(wordSearchTerms, ({ metrics: { conversions } }) => conversions)
        const conversionsValue = sumBy(wordSearchTerms, ({ metrics }) => metrics.conversionsValue)
        const performanceMetrics = isUsingCpa ? { conversions } : { conversionsValue }

        const rawMetrics = { impressions, cost, ...performanceMetrics }
        const metrics = mapValues(rawMetrics, value => formatNumber(value))

        const searchTerms = uniq(wordSearchTerms.map(({ searchTerm }) => searchTerm)).join(',\n')

        return { campaignName, adGroupName, word, searchTerms, ...metrics }
    })

    const rows = sortBy(Object.values(summedMetricsByWord), ({ impressions }) => -impressions)

    const searchTermWordSheet = workbook.addWorksheet('Search Term Words')
    searchTermWordSheet.columns = adjustWidthsToFitText({ columns, rows })
    searchTermWordSheet.addRows(rows)
}

const addRsaWordSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
    landingPageScanFailed: boolean
}) => {
    const { adGroup, workbook } = params
    const { name: adGroupName, campaignName, ads } = adGroup

    const columns: Partial<ExcelJS.Column>[] = [
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Ad Group', key: 'adGroupName' },
        { header: 'RSA Word', key: 'word' },
        { header: 'RSA Assets', key: 'rsaAssets' },
        { header: 'RSA IDs', key: 'rsaIds' },
    ]

    const rawTextAssets = flatMap(ads, ({ id, textAssets }) =>
        textAssets.map(asset => ({ ...asset, rsaId: id }))
    )

    const wordTextAssets = flatMap(rawTextAssets, ({ rsaId, textAsset, words }) =>
        words.map(word => ({ word, textAsset, rsaId }))
    )

    const groupedByWord = groupBy(wordTextAssets, ({ word }) => word)
    const summedByWord = map(groupedByWord, wordTextAssets => {
        const word = wordTextAssets[0].word
        const rsaAssets = uniq(wordTextAssets.map(({ textAsset }) => textAsset)).join(',\n')
        const rsaIds = uniq(wordTextAssets.map(({ rsaId }) => rsaId)).join(',\n')
        return { campaignName, adGroupName, word, rsaAssets, rsaIds }
    })

    const rows = sortBy(Object.values(summedByWord), ({ word }) => word)

    const rsaWordSheet = workbook.addWorksheet('RSA Words')
    rsaWordSheet.columns = adjustWidthsToFitText({ columns, rows })
    rsaWordSheet.addRows(rows)
}

const addLandingPageWordSheet = (params: {
    workbook: ExcelJS.Workbook
    adGroup: Scorecard.AdGroupAlignment
}) => {
    const { adGroup, workbook } = params

    const columns: Partial<ExcelJS.Column>[] = [
        { header: 'Landing Page URLs', key: 'landingPages' },
        { header: 'Landing Page Scanned', key: 'landingPagesScannedOn' },
        { header: 'Landing Page Word', key: 'word' },
        { header: 'Count', key: 'count' },
    ]

    const landingPageUrls = uniq(adGroup.ads.map(({ landingPageNormalizedUrl: url }) => url))
    const landingPagesPerWord = flatMap(landingPageUrls, normalizedUrl => {
        const landingPages = landingPagesByNormalizedUrl.value
        const landingPage = landingPages[normalizedUrl]
        const wordCounts = landingPage.wordCounts

        return Object.entries(wordCounts).map(([word, count]) => ({
            normalizedUrl,
            word,
            count,
        }))
    })

    const groupedByWord = groupBy(landingPagesPerWord, ({ word }) => word)

    const summedByWord = map(groupedByWord, wordLandingPages => {
        const word = wordLandingPages[0].word

        const urls = wordLandingPages.map(({ normalizedUrl: url }) => url)
        const landingPagesScannedOn = getEarliestInspectionDate(urls)
        const landingPages = urls.join(',\n')

        const count = sumBy(wordLandingPages, ({ count }) => count).toString()

        return { landingPages, landingPagesScannedOn, word, count }
    })

    let rows = []
    if (landingPageScanFailed.value) {
        rows = [
            {
                landingPages:
                    'Unable to scan landing page content. Scans can fail due timeouts, restrictions and many other reasons.',
            },
        ]
    } else {
        rows = sortBy(Object.values(summedByWord), ({ count }) => -count)
    }

    const landingPageWordSheet = workbook.addWorksheet('URL Words')
    landingPageWordSheet.columns = adjustWidthsToFitText({ columns, rows })
    landingPageWordSheet.addRows(rows)
}

const formatNumber = (value?: number): string => {
    const numericValue = value ?? 0

    const isInteger = Math.round(numericValue) === numericValue

    if (isInteger) return numericValue.toString()

    return numericValue.toFixed(2)
}

const getEarliestInspectionDate = (landingPageSet: string[]) => {
    const dates = landingPageSet.map(url => {
        const landingPage = landingPagesByNormalizedUrl.value?.[url]
        const lastInspectionAt = landingPage?.lastInspectionAt ?? new Date()
        return parseISO(lastInspectionAt)
    })

    const sortedDates = sortBy(dates, date => date)
    const earliestDate = format(sortedDates[0], 'MMM dd, yyyy')

    return earliestDate
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.header-cell {
    @include pl-24;
}
.sub-scores-container {
    @include container;
    @include br-20;
    @include w-100;
}
.sub-scores-container .item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-top: 1px solid #f7f7f9;
}
.score-table-cell {
    @include flex;
    @include items-center;
    gap: 0.5rem;
}
.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}

.panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-36;
}

.download-button :deep(.o-button.small) {
    @include br-12;
    padding: 1px 14px 0 14px;
    height: 36px;
}

:deep(.improvement-note) {
    @include br-20;
}

@media screen and (max-width: $mq-767-max) {
    .header-cell {
        padding-left: unset;
    }
    .column-overlay {
        height: unset;
        padding-left: unset;
        overflow: unset;
        @include w-100;
        min-width: unset;
    }
    .column-overlay::after {
        display: none;
    }
    :deep(.popout-wrapper) {
        @include w-100;
    }
}
</style>
