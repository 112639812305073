<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
        @score-history-button-clicked="emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1rem" />
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Quality Score Spread</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdownPanel"
                    color="white"
                    size="small"
                    :loading="breakdownLoading"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />
            <div class="bar-chart-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div class="bar-chart">
                    <!-- {{ formattedBarChartData }} -->
                    <BarChart
                        v-if="formattedBarChartData.length"
                        :items="formattedBarChartData"
                        :metric="{ label: 'Impressions', dataType: 'percentage' }"
                        :axisTitles="{
                            y: 'Percentage of Impressions',
                            x: 'Quality Score (1-10)',
                        }"
                        :chartHeight="160"
                        :barWidth="24"
                        :remove-placeholder-container="true"
                    >
                    </BarChart>
                </div>
            </div>
            <Spacer height="1.5rem" />

            <oTable
                :headers="qualityScoreTableHeaders"
                :items="qualityScoreTableItems"
                :fixed-layout="true"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
            >
                <template #column.type="type">
                    <Text
                        v-if="type.value === 'Good'"
                        as="p"
                        size="f-8"
                        color="green"
                        weight="500"
                        >{{ type.value }}</Text
                    >
                    <Text
                        v-if="type.value === 'Okay'"
                        as="p"
                        size="f-8"
                        color="amber"
                        weight="500"
                        >{{ type.value }}</Text
                    >
                    <Text v-if="type.value === 'Poor'" as="p" size="f-8" color="red" weight="500">{{
                        type.value
                    }}</Text>
                </template>

                <!-- Cost column -->
                <template #column.cost="cost">
                    <Money :currency="domainCurrency" :value="cost.value" />
                </template>

                <!-- Conversions column (CPA) -->
                <template #column.conversions="conversions">
                    <NumberVue :value="conversions.value" />
                </template>

                <!-- Conversions Value column (ROAS) -->
                <template #column.conversionsValue="conversionsValue">
                    <Money :currency="domainCurrency" :value="conversionsValue.value ?? 0" />
                </template>

                <!-- CPA column -->
                <template #column.cpa="cpa">
                    <Money :currency="domainCurrency" :value="cpa.value" />
                </template>

                <!-- ROAS column -->
                <template #column.roas="roas">
                    <Roas :value="roas.value ?? 0" />
                </template>

                <!-- Avg column -->
                <template #column.vsAvg="vsAvg">
                    <Text
                        as="span"
                        v-if="!vsAvg.value || vsAvg.value === 0"
                        color="gray"
                        weight="500"
                    >
                        <Percent :value="0" :decimal-places="2" :include-sign="true" />
                    </Text>
                    <Text
                        v-else-if="isUsingCpa && vsAvg.value"
                        as="span"
                        :color="vsAvg.value > 0 ? 'red' : 'green'"
                        weight="500"
                    >
                        <Percent :value="vsAvg.value" :decimal-places="1" :include-sign="true" />
                    </Text>
                    <Text v-else as="span" :color="vsAvg.value < 0 ? 'red' : 'green'" weight="500">
                        <Percent :value="vsAvg.value" :decimal-places="1" :include-sign="true" />
                    </Text>
                </template>
            </oTable>

            <Spacer height="1.5rem" />

            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Score weighted by impressions. Data collected over the last <b>30 days</b>.
                </Text>
            </ImprovementNote>
        </template>
    </ScorecardSection>

    <!-- Campaign Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="() => emit('breakdown-requested')"
        :showPanel="showCampaignBreakdown"
        :closePanel="closeCampaignBreakdown"
        title="Quality Score"
        :width="1176"
        :loading="breakdownLoading"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <oTable
                :headers="breakdownTableHeaders"
                :items="campaignBreakdownTableItems"
                :fixed-layout="true"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
            >
                <template #header.campaign>
                    <div class="header-cell">Campaign</div>
                </template>
                <template #column.campaign="cellData">
                    <div class="campaign-column">
                        <Tooltip
                            :content="cellData.value.length > 52 ? cellData.value : ''"
                            :offset="[0, 8]"
                            placement="top-start"
                            max-width="500px"
                        >
                            <EntityPill
                                type="campaign"
                                :content="cellData.value"
                                :tooltip="false"
                            />
                        </Tooltip>
                    </div>
                </template>
                <template #column.qualityScore="cellData">
                    <NumberVue :value="cellData.value" />
                </template>
                <template #column.cost="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.conversions="cellData">
                    <NumberVue :value="cellData.value" />
                </template>
                <template #column.conversionsValue="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.cpa="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.roas="cellData">
                    <Roas :value="cellData.value" />
                </template>
                <template #column.averageCpa="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.averageRoas="cellData">
                    <Roas :value="cellData.value" />
                </template>
                <template #column.cpaDiff="cellData">
                    <Text
                        as="span"
                        v-if="!cellData.value || cellData.value === 0"
                        color="gray"
                        weight="500"
                    >
                        <Percent :value="0" :decimal-places="2" :include-sign="true" />
                    </Text>
                    <Text
                        as="span"
                        v-else
                        :color="cellData.value > 0 ? 'red' : 'green'"
                        weight="500"
                    >
                        <Percent
                            :value="cellData.value / 100"
                            :decimal-places="2"
                            :include-sign="true"
                        />
                    </Text>
                </template>
                <template #column.roasDiff="cellData">
                    <Text
                        as="span"
                        v-if="!cellData.value || cellData.value === 0"
                        color="gray"
                        weight="500"
                    >
                        <Percent :value="0" :decimal-places="2" :include-sign="true" />
                    </Text>
                    <Text
                        as="span"
                        v-else
                        :color="cellData.value < 0 ? 'red' : 'green'"
                        weight="500"
                    >
                        <Percent
                            :value="cellData.value / 100"
                            :decimal-places="2"
                            :include-sign="true"
                        />
                    </Text>
                </template>
            </oTable>
        </template>
        <template #footer>
            <oButton
                @clicked="
                    downloadCsv({
                        dataSet: 'quality_score_campaign_breakdown',
                        items: campaignBreakdownTableItems,
                        options: {
                            initialColumnHeaders: [
                                'Campaign',
                                'Quality Score',
                                'Cost',
                                'Conversions',
                                'Conversions Value',
                            ],
                        },
                    })
                "
                size="extra-large"
            >
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>
</template>

<script lang="ts">
// Global imports
import { PropType, defineComponent, computed, ref } from 'vue'
import { Scorecard } from '@opteo/types'
import sumBy from 'lodash-es/sumBy'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { downloadCsv } from '@/lib/globalUtils'

// Local imports

import SectionHidden from './SectionHidden.vue'
import { TextOptions, getSectionCopy } from './utils'
import ScorecardSection from './ScorecardSection.vue'
import BreakdownPanel from './BreakdownPanel.vue'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Roas,
    Number as NumberVue,
    ColorTag,
    oButton,
    Tooltip,
    BarChart,
    BarChartTypes,
    EntityPill,
    ChartColor,
} from '@opteo/components-next'

export default defineComponent({
    name: 'QualityScore',
    components: {
        Text,
        Spacer,
        BarChart,
        SectionHidden,
        ScorecardSection,
        oTable,
        Money,
        Percent,
        Roas,
        NumberVue,
        oButton,
        BreakdownPanel,
        Tooltip,
        ColorTag,
        EntityPill,
    },
    props: {
        score: {
            type: Number,
        },
        domainName: {
            type: String,
        },
        domainCurrency: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as PropType<Scorecard.QsScoreDetails>,
        },
        breakdown: {
            type: Object as PropType<Scorecard.QsCampaignBreakdown[]>,
        },
        breakdownLoading: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    emits: ['score-history-button-clicked', 'breakdown-requested'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'
        const { aboveiPadLandscape } = useMediaQuery()

        // Breakdown Panel
        const showCampaignBreakdown = ref(false)
        const closeCampaignBreakdown = () => {
            showCampaignBreakdown.value = false
        }

        const openCampaignBreakdownPanel = () => {
            showCampaignBreakdown.value = true
        }

        const breakdownTableHeaders = computed(
            (): {
                key: string
                text: string
                width?: number
                sortable?: boolean
                noPadding?: boolean
            }[] => {
                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          { key: 'conversions', text: 'Conv.', width: 112, sortable: true },
                          { key: 'cpa', text: 'CPA', width: 112, sortable: true },
                          //   { key: 'averageCpa', text: 'Avg. CPA', width: 100 },
                          { key: 'cpaDiff', text: 'vs Avg.', width: 130, sortable: true },
                      ]
                    : [
                          { key: 'conversionsValue', text: 'Value', width: 112, sortable: true },
                          { key: 'roas', text: 'ROAS', width: 112, sortable: true },
                          //   { key: 'averageRoas', text: 'Avg. ROAS', width: 102 },
                          { key: 'roasDiff', text: 'vs Avg.', width: 130, sortable: true },
                      ]

                return [
                    {
                        key: 'campaign',
                        text: 'Campaign',
                        sortable: true,
                        noPadding: true,
                    },
                    { key: 'qualityScore', text: 'QS', width: 98, sortable: true },
                    { key: 'cost', text: 'Cost', width: 116, sortable: true },
                    ...performanceSpecificHeaders,
                ]
            }
        )

        const campaignBreakdownTableItems = computed<
            (Scorecard.QsCampaignBreakdown & {
                cpa: number
                averageCpa: number
                cpaDiff: number
                roas: number
                averageRoas: number
                roasDiff: number
            })[]
        >(() => {
            const { breakdown: campaignBreakdown } = props

            if (!campaignBreakdown?.length) return []

            const totalCost = sumBy(campaignBreakdown, ({ cost }) => cost ?? 0)
            const totalConv = sumBy(campaignBreakdown, ({ conversions }) => conversions ?? 0)
            const totalConvValue = sumBy(
                campaignBreakdown,
                ({ conversionsValue }) => conversionsValue ?? 0
            )

            const averageCpa = totalCost / totalConv
            const averageRoas = totalConvValue / totalCost

            return campaignBreakdown.map(campaign => {
                const { cost, conversions, conversionsValue } = campaign

                const cpa = cost / conversions
                const roas = conversionsValue / cost

                const cpaDiff = ((cpa - averageCpa) / averageCpa) * 100
                const roasDiff = ((roas - averageRoas) / averageRoas) * 100

                return {
                    ...campaign,
                    cpa,
                    averageCpa,
                    cpaDiff,
                    roas,
                    averageRoas,
                    roasDiff,
                }
            })
        })

        const qualityScoreTableHeaders = computed(
            (): {
                key: string
                text: string
                width?: number
            }[] => {
                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          { key: 'conversions', text: 'Conv.', width: 86 },
                          { key: 'cpa', text: 'CPA', width: 86 },
                      ]
                    : [
                          { key: 'conversionsValue', text: 'Value', width: 88 },
                          { key: 'roas', text: 'ROAS', width: 86 },
                      ]

                return [
                    { key: 'type', text: 'Type' },
                    { key: 'cost', text: 'Cost', width: 94 },
                    ...performanceSpecificHeaders,
                    { key: 'vsAvg', text: 'vs Avg.', width: 102 },
                ]
            }
        )

        const qualityScoreTableItems = computed(() => {
            if (!props.details?.table_data) return []
            const totalCost = sumBy(props.details.table_data, r => r.metrics.cost ?? 0)
            const totalConv = sumBy(props.details.table_data, r => r.metrics.conversions ?? 0)
            const totalConvValue = sumBy(
                props.details.table_data,
                r => r.metrics.conversions_value ?? 0
            )

            const avgCpa = totalCost / totalConv
            const avgRoas = totalConvValue / totalCost

            return props.details.table_data.map((tableItem, index) => {
                const { conversions, conversions_value: conversionsValue, cost } = tableItem.metrics

                const cpa = cost / conversions || 0
                const roas = conversionsValue / cost || 0

                const vsAvg = (props.isUsingCpa ? cpa / avgCpa || 1 : roas / avgRoas || 1) - 1

                return {
                    id: index,
                    type: tableItem.name,
                    vsAvg: vsAvg,
                    match: tableItem.name,
                    cost,
                    conversions,
                    cpa,
                    conversionsValue,
                    roas,
                }
            })
        })

        const sectionCopy = computed(() => {
            return getSectionCopy(textOptions.value, props.score ?? 0)
        })

        const textOptions = computed<TextOptions>(() => {
            return {
                0: [
                    `Quality Scores across your account have room for improvement. Low Quality Scores usually mean ${
                        props.isUsingCpa ? 'a higher overall CPA' : 'a lower average ROAS overall'
                    }. Spending more efficiently begins with improving Quality Scores.`,
                    `According to our analysis, ${
                        props.invalid ? '' : props.details?.factor
                    } is having the most detrimental effect on Quality Scores across your account. This should provide a useful starting point for any improvements moving forward—consider making adjustments in this area to boost Quality Scores and reduce your average cost per click.`,
                ],
                50: [
                    `Quality Scores are satisfactory across your account, but cost per click could be reduced with some improvements. Efficient budget allocation often begins with improving Quality Scores.`,
                    `According to our analysis, ${
                        props.invalid ? '' : props.details?.factor
                    } is having the most detrimental effect on Quality Scores across your account. This should provide a useful starting point for any improvements moving forward—consider making adjustments in this area to boost Quality Scores and reduce your average cost per click.`,
                ],
                70: [
                    `Overall, Quality Scores across your account are performing well. Quality Scores are vitally important when it comes to spending efficiently and keeping your average cost per click low. Moving forward, continue to monitor Quality Scores as you scale.`,
                    `Our analysis suggests that ${
                        props.invalid ? '' : props.details?.factor
                    } could be an area with room for improvement—consider investigating any potential opportunities in this area over the coming months.`,
                ],
            }
        })

        const formattedBarChartData = computed<BarChartTypes.BarChartItem[]>(() => {
            let sum = 1
            for (const item of props.details?.series ?? []) {
                sum += +item
            }

            return (props.details?.series ?? []).map((item, index) => {
                let color: ChartColor = 'opteo-green'
                if (index + 1 <= 3) {
                    color = 'opteo-red'
                }
                if (index + 1 > 3 && index + 1 < 7) {
                    color = 'opteo-amber'
                }
                return {
                    y: item / sum,
                    label: `${index + 1}`,
                    color,
                }
            })
        })

        return {
            sectionType: Scorecard.SectionTypes.qs,
            sectionName: Scorecard.SectionNames.qs,
            qualityScoreTableHeaders,
            qualityScoreTableItems,
            formattedBarChartData,
            sectionCopy,
            isPdf,
            aboveiPadLandscape,
            // Breakdown Panel
            showCampaignBreakdown,
            closeCampaignBreakdown,
            breakdownTableHeaders,
            campaignBreakdownTableItems,
            downloadCsv,
            emit,
            openCampaignBreakdownPanel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.bar-chart-container {
    @include container;
    @include br-20;
    @include ph-32;
    @include pt-32;
    @include pb-28;
    overflow: auto;
}

:deep(.improvement-note) {
    @include br-20;
}

// Campaign Breakdown Panel
.title-tag {
    @include container;
    @include flex-center;
    @include br-12;
    @include ph-16;
    height: 2.75rem;
}
.header-cell {
    @include pl-24;
}
.campaign-column {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.campaign-column::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
</style>
