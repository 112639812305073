import { useRouter } from 'vue-router'

import { Entity } from '@opteo/types'
import {
    ValidGadsEntity,
    ValidOpteoEntity,
    type EntityPillLinkProps,
    type ValidEntity,
} from '@/components/global/Entity/types'
import { Routes } from '@/router/routes'

import { useDeepLinking } from './useDeepLinking'

import type { EntityPillProps } from '@opteo/components-next'

type Cursor = EntityPillProps['cursor']

export function useEntityPillLink<T extends ValidEntity>({
    deepLinkParams,
    entityType,
    ocid,
}: {
    deepLinkParams: Entity.Ids<T>['entityIds'] | null
    entityType: T
    ocid: ReturnType<typeof useDeepLinking>['ocid']
}) {
    const router = useRouter()

    const isOpteoEntityType = (entityType: ValidEntity): entityType is ValidOpteoEntity => {
        return ValidOpteoEntity.some(opteoEntity => opteoEntity === entityType)
    }

    const constructGadsUrl = (gadsEntityType: ValidGadsEntity, ocidStr: string) => {
        const entityRequiresIds = !Entity.NoIdsEntity.some(noIdEntity => noIdEntity === entityType)

        if (entityRequiresIds && deepLinkParams === null) {
            console.log('no ids sent, return early')
            return
        }

        const EntityUrlPathRel = {
            [Entity.EntityLocation.AccountLevel]: 'overview',
            [Entity.EntityLocation.Ad]: 'ads',
            [Entity.EntityLocation.AdGroup]: 'keywords',
            [Entity.EntityLocation.Campaign]: 'adgroups',
            [Entity.EntityLocation.ConversionAction]: 'conversions',
            [Entity.EntityLocation.Experiment]: 'adgroups',
            [Entity.EntityLocation.Keyword]: 'keywords',
            [Entity.EntityLocation.Location]: 'locations',
            [Entity.EntityLocation.NegativeKeyword]: 'keywords/negative',
            [Entity.EntityLocation.NegativeList]: 'negativekeywordlistdetails',
            [Entity.EntityLocation.PlacementExclusionList]: 'placementexclusionlists/detail',
            [Entity.EntityLocation.ProductGroup]: 'productgroups',
            [Entity.EntityLocation.SearchTerm]: 'keywords/searchterms',
            [Entity.EntityLocation.Placement]: 'placements',
        } as const satisfies Record<ValidGadsEntity, string>

        const baseUrl = new URL(`aw/${EntityUrlPathRel[gadsEntityType]}`, 'https://ads.google.com')
        const urlParams = new URLSearchParams({
            ocid: ocidStr,
        })

        const getUrlStr = () => {
            baseUrl.search = urlParams.toString()
            const urlStr = baseUrl.toString()

            return urlStr
        }

        // This entity type doesn't require any ids: return early with the base url and the OCID as a param
        if (!entityRequiresIds) {
            return getUrlStr()
        }

        // Else, this entity type requires ids. We need to append the ids to the url after a few checks
        if (!deepLinkParams) {
            // TODO(deepLinking): throwing an error results in an infinite loop, figure out why and fix before release
            // throw new Error('no entity ids provided for an entity that requires them')
            console.log('entityType', entityType)
            console.log('No entity IDs provided for an entity that requires them')
            return
        }

        const areEntityIdsInvalid = Object.values(deepLinkParams).some(entityId => {
            return typeof entityId === 'number' && entityId < 0
        })

        if (areEntityIdsInvalid) {
            throw new Error(
                'One of the attached entity IDs is either 0 or a negative value. This is likely an old improvement that does not have access to the required entity IDs.'
            )
        }

        const appendToUrlParams = (key: 'campaignId' | 'adGroupId' | 'sharedSetId') => {
            if (deepLinkParams && deepLinkParams[key]) {
                urlParams.append(key, `${deepLinkParams[key]}`)
            }
        }

        const possibleIdKeys = ['campaignId', 'adGroupId', 'sharedSetId'] as const
        possibleIdKeys.forEach(id => {
            appendToUrlParams(id)
        })

        return getUrlStr()
    }

    const constructOpteoUrl = (opteoEntityType: ValidOpteoEntity) => {
        const EntityUrlRouteRel = {
            [Entity.EntityLocation.CampaignGroup]: Routes.CampaignGroups,
        } as const satisfies Record<ValidOpteoEntity, Routes>

        const urlBase = window.location.origin
        const { href } = router.resolve({ name: EntityUrlRouteRel[opteoEntityType] })

        return `${urlBase}${href}`
    }

    const constructUrl = () => {
        if (isOpteoEntityType(entityType)) {
            return constructOpteoUrl(entityType)
        }

        // For testing without saved OCID, comment this line out
        if (!ocid.value || !entityType) return
        // If testing and previous line commented out, ocid will show as null in the constructed URL
        const ocidStr = `${ocid.value}`

        return constructGadsUrl(entityType, ocidStr)
    }

    const url = constructUrl()

    let cursor: Cursor
    if (url) {
        cursor = 'pointer'
    }

    return {
        url,
        cursor,
    }
}
