<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="Scorecard.SectionTypes.rsa"
        :section-name="Scorecard.SectionNames.rsa"
        :mode="mode"
        @score-history-button-clicked="$emit('score-history-button-clicked')"
    >
        <template #content>
            <!-- Paragraph -->
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1rem" />

            <!-- RSA Summary -->
            <SubSectionHeader>
                <Text as="h6" weight="600">RSA Summary</Text>
            </SubSectionHeader>
            <Summary :apply-pdf-shadow-fix="mode === 'pdf'" :summary-items="summaryItems" />
            <Spacer height="2.5rem" />

            <!-- RSA Health -->
            <SubScores
                :apply-pdf-shadow-fix="mode === 'pdf'"
                section-title="RSA Health"
                :sub-scores="subScores"
            >
                <template #button>
                    <oButton
                        v-if="mode !== 'pdf' && aboveiPadLandscape"
                        @clicked="openBreakdown"
                        color="white"
                        size="small"
                    >
                        Campaign Breakdown
                    </oButton>
                </template>
            </SubScores>
            <Spacer height="2.5rem" />

            <!-- Worst Performing RSAs -->
            <SubSectionHeader>
                <Text as="h6" weight="600">Worst Performing RSAs</Text>
            </SubSectionHeader>
            <div class="table-container">
                <div v-if="worstRsa" class="worst-rsa">
                    <RsaAdContainer
                        :rsa="worstRsa"
                        :are-ad-customisers-stripped="true"
                        :is-mobile-mode="true"
                        :hide-border="true"
                        :full-width="true"
                    />
                </div>
                <RsaTable
                    class="rsa-table"
                    table-type="worstRsas"
                    :headers="worstRsasTableHeaders"
                    :items="worstRsasTableItems"
                    :domain-currency="domainCurrency"
                    :is-using-cpa="isUsingCpa"
                    :mode="mode"
                    :sub-scores-per-rsa="subScoresPerRsa"
                    :enabled-rsas="enabledRsas"
                    :enabled-rsas-loading="enabledRsasLoading"
                    :border-radius="0"
                    @mouseenter-row="rsaAdId => changeHoveredWorstRsaId(rsaAdId)"
                />
            </div>
        </template>
    </ScorecardSection>

    <BreakdownPanel
        @breakdown-requested="() => $emit('breakdown-requested')"
        :showPanel="isBreakdownOpen"
        :closePanel="closeBreakdown"
        title="RSA Health"
        :width="1176"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="([campaignName, items], index) in filteredBreakdownTableItems"
                :class="[
                    'campaign-breakdown-container',
                    {
                        'mb-36':
                            filteredBreakdownTableItems &&
                            index !== filteredBreakdownTableItems.length - 1,
                    },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaignName"
                    :currency="domainCurrency"
                    :cost="breakdownItems?.totalCostPerCampaignName[campaignName] ?? 0"
                />

                <Spacer height="2.25rem" />

                <RsaTable
                    table-type="breakdown"
                    :headers="breakdownHeaders"
                    :items="items"
                    :domain-currency="domainCurrency"
                    :is-using-cpa="isUsingCpa"
                    :mode="mode"
                    :sub-scores-per-rsa="subScoresPerRsa"
                    :enabled-rsas="enabledRsas"
                    :enabled-rsas-loading="enabledRsasLoading"
                />
            </div>
            <!-- Show More Button -->
            <div v-if="!showMoreButtonDisabled" class="panel-button-container">
                <oButton
                    color="white"
                    size="medium"
                    @clicked="showMoreTables"
                    :disabled="showMoreButtonDisabled"
                >
                    Show More
                </oButton>
            </div>
        </template>

        <template #footer>
            <oButton
                v-if="breakdownItems"
                @clicked="
                    downloadCsv({
                        dataSet: 'rsa_breakdown',
                        items: breakdownItems.csv,
                    })
                "
                size="extra-large"
            >
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>
</template>

<script setup lang="ts">
import { formatDistanceToNowStrict } from 'date-fns'
import { computed, ref, watchEffect } from 'vue'
import { RsaWriter, Scorecard } from '@opteo/types'
import { ColorTag, oButton, Spacer, Text, Tooltip } from '@opteo/components-next'
import RsaAdContainer from '@/components/rsaWriter/RsaAdContainer.vue'

import { Endpoint, useAPI } from '@/composition/api/useAPI'
import useMediaQuery from '@/composition/global/useMediaQuery'
import {
    calculateCpaClamped,
    calculateRoas,
    downloadCsv,
    sortCollectionBy,
} from '@/lib/globalUtils'

import { formatBreakdown, getSectionCopy, type TextOptions } from '../utils'
import BreakdownTableHeader from '../BreakdownTableHeader.vue'
import BreakdownPanel from './../BreakdownPanel.vue'
import ScorecardSection from '../ScorecardSection.vue'
import SubScores from '../SubScores.vue'
import SubSectionHeader from '../SubSectionHeader.vue'
import Summary from '../Summary.vue'
import RsaTable from './RsaTable.vue'
import { SubScoreHeaderText } from './types'

// NOTE: for some reason, if you import it using the @/ path it will error
import type {
    SectionProps,
    SubScore,
    SummaryItem,
} from '../../../composition/toolkit/scorecard/types'
import type { CsvItem, BreakdownTableItem, WorstRsasTableItem } from './types'

type VsAvgsPerRsa = Record<string, { vsAvgCpa: number; vsAvgRoas: number }>

const formatInfinity = (performanceVsAvg: Scorecard.RsaPerformanceVsAvg) => {
    return performanceVsAvg === 'infinite' ? Infinity : performanceVsAvg
}

const props = defineProps<SectionProps<'rsa'>>()

// Summary

const textOptions = computed(() => {
    return {
        0: [
            `Healthy Responsive Search Ads (RSAs) are key to driving better ad performance. Ensuring each ad group has sufficient variations allows for effective testing and optimization. Regularly updating RSAs with fresh assets, incorporating high-converting n-grams, and maintaining asset uniqueness can improve relevance and engagement, as well as boost overall performance.`,
            `There are some opportunities to improve the overall health of RSAs across your account. Where possible, aim to incorporate high-converting n-grams in your asset copy, use headlines and descriptions that are relevant to each individual ad group, and replace any underperforming assets by creating ad variations with fresh copy informed by your performance data.`,
        ],
        70: [
            `RSAs across your account are in good health and appear to be performing well. You are using high-converting n-grams in your asset copy, have a spread of unique assets across different ad groups, and a healthy percentage of your existing assets have been assigned a high performance rating.`,
            `To build on these positives and further improve RSA performance, consider creating some fresh campaigns, updating and adjusting existing assets, or creating ad variations to test any new ideas.`,
        ],
    }
})

const sectionCopy = computed(() => getSectionCopy(textOptions.value, props.score ?? 0))

const summaryItems = computed<SummaryItem[]>(() => {
    if (!props.details) return []

    const {
        enabledRsaCount,
        avgRsaCountPerAdGroup,
        rsasNotTouchedIn90dCount,
        latestAdVariationLaunched,
    } = props.details.metricGrid

    const rsasEditedLast90dPercent = 1 - rsasNotTouchedIn90dCount / enabledRsaCount
    const adVariationLaunched = latestAdVariationLaunched
        ? formatDistanceToNowStrict(new Date(latestAdVariationLaunched)) + ' ago'
        : 'Never'

    return [
        {
            title: 'Enabled RSAs',
            content: enabledRsaCount,
        },
        {
            title: 'Per Ad Group (Avg.)',
            content: avgRsaCountPerAdGroup,
            decimalPlaces: 1,
        },
        {
            title: 'Edited in Last 90 Days',
            content: rsasEditedLast90dPercent,
            isPercent: true,
            decimalPlaces: 0,
        },
        {
            title: 'Ad Variation Launched',
            content: adVariationLaunched,
        },
    ]
})

// Breakdown

const { aboveiPadLandscape } = useMediaQuery()

const isBreakdownOpen = ref(false)
const openBreakdown = () => (isBreakdownOpen.value = true)
const closeBreakdown = () => {
    resetLimitTables()
    isBreakdownOpen.value = false
}

const breakdownHeaders = computed(() => {
    const performanceSpecificHeaders = props.isUsingCpa
        ? [
              { key: 'conversions', text: 'Conv.', width: 92, noPadding: true, sortable: true },
              { key: 'cpa', text: 'CPA', width: 92, noPadding: true, sortable: true },
          ]
        : [
              {
                  key: 'conversionsValue',
                  text: 'Value',
                  width: 92,
                  noPadding: true,
                  sortable: true,
              },
              { key: 'roas', text: 'ROAS', width: 92, noPadding: true, sortable: true },
          ]

    return [
        { key: 'rsa', text: 'RSA', width: 216, noPadding: true, sortable: true },
        { key: 'adGroup', text: 'Ad Group', width: 180, noPadding: true, sortable: true },
        { key: 'cost', text: 'Cost', width: 92, noPadding: true, sortable: true },
        ...performanceSpecificHeaders,
        { key: 'vsAvg', text: 'vs Avg.', width: 102, noPadding: true, sortable: true },
        { key: 'health', text: 'Health', width: 94, noPadding: true, sortable: true },
        { key: 'action', text: 'Action', width: 100, noPadding: true },
    ]
})

/**
 * @description Formats a breakdown table item into a CSV item by splitting `vsAvg` into `vsAvgCpa` and `vsAvgRoas`
 * and ordering the properties.
 * @param args.breakdownTableItems
 * @param args.vsAvgsPerRsa
 * @returns The CSV items
 */
const breakdownTableItemsToCsvItems = ({
    breakdownTableItems,
    vsAvgsPerRsa,
}: {
    breakdownTableItems: BreakdownTableItem[]
    vsAvgsPerRsa: VsAvgsPerRsa
}) => {
    const csvItems: CsvItem[] = breakdownTableItems.map(item => {
        const { vsAvg, ...rest } = item

        const { vsAvgCpa, vsAvgRoas } = vsAvgsPerRsa[item.rsa]

        return {
            campaign: rest.campaign,
            adGroup: rest.adGroup,
            rsa: rest.rsa,
            cost: rest.cost,
            conversions: rest.conversions,
            conversionsValue: rest.conversionsValue,
            cpa: rest.cpa,
            avgCpa: rest.avgCpa,
            vsAvgCpa,
            roas: rest.roas,
            avgRoas: rest.avgRoas,
            vsAvgRoas,
            health: rest.health,
            assetUniqueness: rest.assetUniqueness,
            convertingNgramsInAssets: rest.convertingNgramsInAssets,
            assetPerformanceRating: rest.assetPerformanceRating,
        }
    })

    return csvItems
}

const breakdownItems = computed(() => {
    if (!props.breakdown) return

    const { totalCostPerCampaign, rsas } = props.breakdown

    const campaignsByCostDesc = Object.entries(totalCostPerCampaign)
        .sort(([, costA], [, costB]) => costB - costA)
        .map(([campaignResourceName]) => campaignResourceName)

    const totalCostPerCampaignName: Record<string, number> = {}
    const vsAvgsPerRsa: VsAvgsPerRsa = {}

    const rsasByCampaignCost = sortCollectionBy({
        collectionToSort: rsas,
        sortingValue: rsa => rsa.campaignResourceName,
        arrayToSortBy: campaignsByCostDesc,
    }).map(rsa => {
        const {
            adId,
            adGroupId,
            adGroupName,
            campaignResourceName,
            campaignId,
            campaignName,
            convertingNgramsInAssets,
            assetUniqueness,
            assetPerformanceRating,
        } = rsa
        const {
            cost,
            conversions,
            conversionsValue,
            cpaPerformanceVsAvg,
            roasPerformanceVsAvg,
            avgCpa,
            avgRoas,
        } = rsa.metrics

        if (!totalCostPerCampaignName[campaignName]) {
            totalCostPerCampaignName[campaignName] = totalCostPerCampaign[campaignResourceName]
        }

        const formattedCpaPerformanceVsAvg = formatInfinity(cpaPerformanceVsAvg)
        const formattedRoasPerformanceVsAvg = formatInfinity(roasPerformanceVsAvg)

        vsAvgsPerRsa[adId] = {
            vsAvgCpa: formattedCpaPerformanceVsAvg,
            vsAvgRoas: formattedRoasPerformanceVsAvg,
        }

        // Average of the three sub-scores
        const health =
            [assetUniqueness, convertingNgramsInAssets, assetPerformanceRating].reduce(
                (a, b) => a + b,
                0
            ) / 3

        const breakdownTableItem: BreakdownTableItem = {
            campaign: campaignName,
            campaignId,
            rsa: adId,
            adGroup: adGroupName,
            adGroupId,
            cost,
            conversions,
            conversionsValue,
            cpa: calculateCpaClamped({ conversions, cost }),
            roas: calculateRoas({ conversionsValue, cost }),
            avgCpa,
            avgRoas,
            health,
            assetUniqueness,
            convertingNgramsInAssets,
            assetPerformanceRating,
            vsAvg: props.isUsingCpa ? formattedCpaPerformanceVsAvg : formattedRoasPerformanceVsAvg,
        }

        return breakdownTableItem
    })

    const breakdownTableItems = formatBreakdown(rsasByCampaignCost)

    const csvItems: CsvItem[] = breakdownTableItems.flatMap(([, breakdownTableItems]) => {
        return breakdownTableItemsToCsvItems({ breakdownTableItems, vsAvgsPerRsa }).sort(
            (rsaA, rsaB) => {
                if (rsaA.adGroup < rsaB.adGroup) {
                    return -1
                }

                if (rsaA.adGroup > rsaB.adGroup) {
                    return 1
                }

                return 0
            }
        )
    })

    return {
        totalCostPerCampaignName,
        table: breakdownTableItems,
        csv: csvItems,
    }
})

// Show more tables button

let limitTables = ref(8)
function showMoreTables() {
    limitTables.value += 8
}
function resetLimitTables() {
    limitTables.value = 8
}
const filteredBreakdownTableItems = computed(() => {
    return breakdownItems.value?.table.slice(0, limitTables.value) ?? []
})
const showMoreButtonDisabled = computed(() => {
    return breakdownItems?.value && limitTables.value >= breakdownItems.value.table.length
})

const subScoresPerRsa = computed(() => {
    if (!props.breakdown) return {}

    const { rsas } = props.breakdown

    return rsas.reduce<Record<string, Scorecard.RsaSubScores>>(
        (
            acc,
            { adId: rsaAdId, convertingNgramsInAssets, assetUniqueness, assetPerformanceRating }
        ) => {
            acc[rsaAdId] = {
                convertingNgramsInAssets,
                assetUniqueness,
                assetPerformanceRating,
            }

            return acc
        },
        {}
    )
})

// Sub-scores

const subScoreTextOptions: Record<keyof Scorecard.RsaDetailsSubScores, TextOptions> = {
    convertingNgramsInAssets: {
        0: `To ensure your ads are relevant to searchers, aim to include more converting n-grams in your asset copy.`,
        70: `You have a healthy percentage of converting n-grams in your headlines and descriptions, this should help to ensure your ads are relevant to searchers.`,
    },
    assetUniqueness: {
        0: `Make sure to write unique headlines and descriptions for each ad group, accounting for the specific themes and keywords each ad group focuses on.`,
        70: `You have a healthy level of asset uniqueness across ad groups, which suggests your RSAs are relevant to the specific keywords and themes of each ad group.`,
    },
    assetPerformanceRating: {
        0: `A reasonable percentage of your existing assets have been assigned a poor performance rating. Consider testing alternatives to boost ad performance.`,
        70: `A healthy percentage of your existing headlines and descriptions have been assigned a high performance rating. Consider testing new creative approaches.`,
    },
    adVariations: {
        0: `Consider implementing ad variations to create and test alternative approaches to copywriting, either across multiple campaigns, or your account as a whole.`,
        70: `Your account is actively making use of ad variations to create and test alternative copywriting approaches.`,
    },
}

const subScores = computed<SubScore[]>(() => {
    if (!props.details) return []

    const { convertingNgramsInAssets, assetUniqueness, assetPerformanceRating, adVariations } =
        props.details.subScores

    return [
        {
            score: convertingNgramsInAssets,
            title: SubScoreHeaderText.convertingNgramsInAssets,
            copies: subScoreTextOptions.convertingNgramsInAssets,
        },
        {
            score: assetUniqueness,
            title: SubScoreHeaderText.assetUniqueness,
            copies: subScoreTextOptions.assetUniqueness,
        },
        {
            score: assetPerformanceRating,
            title: SubScoreHeaderText.assetPerformanceRating,
            copies: subScoreTextOptions.assetPerformanceRating,
        },
        {
            score: adVariations,
            title: SubScoreHeaderText.adVariations,
            copies: subScoreTextOptions.adVariations,
        },
    ]
})

// Enabled RSAs for Tables

const domainId = props.accountInfo?.accountId
const isValidDomainId = computed(() => !!domainId && typeof domainId === 'number')
const shouldMakeAPICall = computed(() => isValidDomainId.value && props.mode === 'app')

const { data: enabledRsas = ref([]), loading: enabledRsasLoading = ref(false) } =
    shouldMakeAPICall.value
        ? useAPI<RsaWriter.GadsRsa[]>(Endpoint.GetRsas, {
              uniqueId: () => '',
              body: {
                  domainId: domainId,
              },
          })
        : {}

// Worst Performing RSAs Table

const relevantModeWorstRsas = computed(() => {
    if (!props.details) return []

    const { cpaMode, roasMode } = props.details.worstRsas

    const relevantModeRsas = props.isUsingCpa ? cpaMode : roasMode

    return relevantModeRsas
})

const hoveredWorstRsaId = ref<number | null>(null)
const worstRsa = ref<Scorecard.WorstRsa>()

watchEffect(() => {
    if (!relevantModeWorstRsas.value.length) return

    if (!hoveredWorstRsaId.value) {
        worstRsa.value = relevantModeWorstRsas.value[0]
        return
    }

    worstRsa.value =
        relevantModeWorstRsas.value.find(rsa => rsa.adId === hoveredWorstRsaId.value) ||
        relevantModeWorstRsas.value[0]
})

const changeHoveredWorstRsaId = (rsaAdId: number) => {
    hoveredWorstRsaId.value = rsaAdId
}

const worstRsasTableHeaders = computed(() => {
    const performanceSpecificHeader = props.isUsingCpa
        ? { key: 'cpa', text: 'CPA', width: 76, noPadding: true }
        : { key: 'roas', text: 'ROAS', width: 76, noPadding: true }

    return [
        { key: 'rsa', text: 'RSA', width: 220, noPadding: true },
        { key: 'cost', text: 'Cost', width: 78, noPadding: true },
        performanceSpecificHeader,
        { key: 'vsAvg', text: 'vs Avg.', width: 88, noPadding: true },
    ]
})

const worstRsasTableItems = computed<WorstRsasTableItem[]>(() => {
    if (!props.details || !relevantModeWorstRsas.value.length) return []

    return relevantModeWorstRsas.value.map(rsa => {
        const { adId, adGroupId, campaignId } = rsa
        const { cost, conversions, conversionsValue, performanceVsAvg } = rsa.metrics

        return {
            rsa: adId,
            adGroupId,
            campaignId,
            cost,
            cpa: calculateCpaClamped({ cost, conversions }),
            roas: calculateRoas({ cost, conversionsValue }),
            vsAvg: formatInfinity(performanceVsAvg),
        }
    })
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}
.title-tag {
    @include container;
    @include flex-center;
    @include br-12;
    @include ph-16;
    height: 2.75rem;
}
.rsa-summary-title-container {
    @include flex;
    @include items-center;
    @include justify-between;
}

.table-container {
    @include container;
    @include opteo-border;
    @include br-20;
    overflow: hidden;

    .rsa-table {
        border-top: 1px solid;
        @include opteo-border;
        cursor: default;
    }
}
.worst-rsa {
    min-height: 14.5rem;
}
// :deep(.text-ad) {
//     box-shadow: unset;
//     border-radius: unset;
// }

// :deep(.inner-container) {
//     @include pv-32;
//     @include ph-40;
// }

.panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-36;
}

// Responsive
@media screen and (max-width: $mq-480-max) {
    .worst-rsa {
        min-height: 156px;
    }
    :deep(.inner-container) {
        @include pa-28;
    }
}
</style>
