import type { EntityPillProps } from '@opteo/components-next'
import { type Account, Entity } from '@opteo/types'

export const ValidGadsEntity = [
    Entity.EntityLocation.AccountLevel,
    Entity.EntityLocation.Ad,
    Entity.EntityLocation.AdGroup,
    Entity.EntityLocation.Campaign,
    Entity.EntityLocation.ConversionAction,
    Entity.EntityLocation.Experiment,
    Entity.EntityLocation.Keyword,
    Entity.EntityLocation.Location,
    Entity.EntityLocation.NegativeKeyword,
    Entity.EntityLocation.NegativeList,
    Entity.EntityLocation.PlacementExclusionList,
    Entity.EntityLocation.ProductGroup,
    Entity.EntityLocation.SearchTerm,
    Entity.EntityLocation.Placement,
] as const
export type ValidGadsEntity = (typeof ValidGadsEntity)[number]

export const ValidOpteoEntity = [Entity.EntityLocation.CampaignGroup] as const
export type ValidOpteoEntity = (typeof ValidOpteoEntity)[number]

export const ValidEntity = [...ValidGadsEntity, ...ValidOpteoEntity] as const
export type ValidEntity = (typeof ValidEntity)[number]

/**
 * @description Props for an `EntityPillLink` component, which includes the props for the wrapped `EntityPill` and the specific deep linking props.
 * This type will infer which ids need to be passed based on the entity type.
 * `deepLinkParams` can optionally be `null` for entities that need ids (e.g. ad, ad group, campaign, etc.) if the object coming from the
 * API does not contain them. This is especially relevant for old or completed improvements that may or may not have the ids in the body.
 */
export type EntityPillLinkProps<T extends ValidEntity> = {
    entityPillData: EntityPillProps & { type: T }
    deepLinkParams: Entity.Ids<T>['entityIds'] | null
    accountId?: Account.ID
}

export type EntityPillListLinkProps<T extends ValidEntity[]> = {
    items: EntityPillLinkProps<T[number]>[]
    scrollToRight?: boolean
    accountId?: Account.ID
}
