import { computed, inject, ComputedRef, ref } from 'vue'

import some from 'lodash-es/some'
import capitalize from 'lodash-es/capitalize'

import { DonutChartTypes, useRoas } from '@opteo/components-next'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AdjustDeviceBidsV2, Improvement, Targets } from '@opteo/types'
import { sortTable } from '@opteo/components-next'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { calculateCpa, calculateRoas, getProperDeviceLabel } from './utils'
import { useDeviceTables } from './useDeviceTables'
import { useEntityInfo } from './useEntityInfo'
import { TableHeader, TableItem } from './types'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

interface UseAdjustSingleDeviceBid {
    entity: 'adgroup' | 'campaign' | 'campaign-group'
    entityLabel: string
    entityName: string
    highlightedSegment: string
    deviceTableHeaders: TableHeader[]
    deviceTableItems: TableItem[]
    donutChartItems: DonutChartTypes.DonutChartItem[]
    domainCurrencyCode?: CurrencyCode
    proposeIncreaseBid: boolean
    currentBidMod: number
    newBidMod: number
    formattedSegmentCpa: string
    formattedSegmentRoas: string
    improvementStatisticsItems: {
        key: string
        value: string
        title: string
    }[]
    lookbackWindow: number
    updateBid: (value: number) => void
    isUsingCpa: boolean
    formattedEntityAverage: string
    differenceFromEntityAverage: number
    differenceFromTarget: number
    formattedTarget: string
    entityPillList?: { content: string; type: string }[]
    entityPillLinkProps?: EntityPillLinkProps<ValidEntity>
    entityPillLinkListItems?: EntityPillLinkProps<ValidEntity>[]
}

export function useAdjustSingleDeviceBid(): UseImprovement<UseAdjustSingleDeviceBid> {
    const { improvement, lastUpdated, title } =
        useImprovement<AdjustDeviceBidsV2.DeviceBidsRecActions>()

    const { body } = checkImprovement(improvement)

    // Find relevant device
    const singleDeviceBidMod = body.devices.find(d => !d.invalid)

    if (!singleDeviceBidMod || !singleDeviceBidMod.current_bid_mod) {
        throw new Error('NO_BIDS_TO_CHANGE')
    }

    const currentBidMod = singleDeviceBidMod.current_bid_mod
    const newBidMod = singleDeviceBidMod.new_bid_mod!
    const proposeIncreaseBid = newBidMod > currentBidMod

    const isUsingCpa =
        !body.performance_mode || body.performance_mode === Targets.PerformanceMode.CPA

    const highlightedSegment = getProperDeviceLabel(singleDeviceBidMod.device_label)

    const segmentCpa = calculateCpa(singleDeviceBidMod.cost, singleDeviceBidMod.all_conversions)
    const segmentRoas = calculateRoas(
        singleDeviceBidMod.cost,
        singleDeviceBidMod.all_conversions_value
    )

    const campaignGroupPerformanceMetric =
        (isUsingCpa ? body.campaign_group.target_cpa : body.campaign_group.target_roas) ?? 0

    const { displayValue: formattedTargetCpa } = useDomainMoney({
        value: campaignGroupPerformanceMetric ?? 0,
    }).value

    const { displayValue: formattedTargetRoas } = useRoas({
        value: campaignGroupPerformanceMetric ?? 0,
    })

    const formattedTarget = isUsingCpa ? formattedTargetCpa.value : formattedTargetRoas.value

    const {
        entity,
        entityLabel,
        entityName,
        entityAverage,
        entityPillList,
        entityPillLinkProps,
        entityPillLinkListItems,
        campaignGroup,
        formattedEntityAverage,
    } = useEntityInfo(body, isUsingCpa)

    const targetSet =
        (isUsingCpa && body.campaign_group.target_cpa_set) ||
        (!isUsingCpa && body.campaign_group.target_roas_set)

    const { deviceTableHeaders, deviceTableItems } = useDeviceTables(
        body.devices,
        isUsingCpa,
        formattedTarget,
        entityAverage,
        targetSet
    )

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const formattedSegmentCpa = useDomainMoney({
        value: segmentCpa,
    }).value.displayValue.value

    const formattedSegmentRoas = useRoas({
        value: segmentRoas,
    }).displayValue.value

    const differenceFromEntityAverage = isUsingCpa
        ? (segmentCpa - entityAverage) / entityAverage
        : (segmentRoas - entityAverage) / entityAverage

    const differenceFromTarget = isUsingCpa
        ? (segmentCpa - campaignGroupPerformanceMetric) / campaignGroupPerformanceMetric
        : (segmentRoas - campaignGroupPerformanceMetric) / campaignGroupPerformanceMetric

    const improvementStatisticsItems = [
        isUsingCpa
            ? {
                  key: 'segmentCpa',
                  value: formattedSegmentCpa,
                  title: 'Segment CPA',
              }
            : {
                  key: 'segmentRoas',
                  value: formattedSegmentRoas,
                  title: 'Segment ROAS',
              },
        {
            key: 'entityAverage',
            value: formattedEntityAverage,
            title: `${entityLabel === 'ad group' ? 'Ad Group' : capitalize(entityLabel)} ${
                isUsingCpa ? 'CPA' : 'ROAS'
            }`,
        },

        {
            key: 'campaignGroupTarget',
            value: formattedTarget,
            title: `Campaign Group Target ${isUsingCpa ? 'CPA' : 'ROAS'}`,
        },
    ]

    let extraDetails: { resource_name: string; cpc_bid: number }[] = []

    /* Single Bid Mod Only */
    const updateBid = (value: number) => {
        if (entity === 'adgroup' || entity === 'campaign') {
            extraDetails = [
                { resource_name: singleDeviceBidMod.resource_name!, cpc_bid: +value.toFixed(2) },
            ]
            return
        }
        const { criteria } = singleDeviceBidMod
        extraDetails = campaignGroup.campaigns
            ? campaignGroup.campaigns.map(
                  (campaign_data: { resource_name: string; name: string }) => {
                      const campaign_criteria_resource_name = campaign_data?.resource_name.replace(
                          'campaigns',
                          'campaignCriteria'
                      )
                      return {
                          resource_name: `${campaign_criteria_resource_name}~${criteria}`,
                          cpc_bid: +value.toFixed(2),
                      }
                  }
              )
            : []
    }

    const donutChartItems = sortTable(
        'label',
        'ASC',
        body.devices.map(device => {
            return {
                y: device.cost,
                label: getProperDeviceLabel(device.device_label),
                highlighted: device.device_label === singleDeviceBidMod.device_label,
            }
        })
    )

    const lookbackWindow = body.window ?? 90

    const pushActionText = ref('Apply Bid Adjustment')

    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying device bid adjustment..`,
        `Confirming changes..`,
        `Bid adjustment applied successfully.`,
    ])

    function validate(extraDetails: { resource_name: string; cpc_bid: number }[]) {
        return !some(extraDetails, ed => {
            const { cpc_bid: newBidMod, resource_name: resourceName } = ed
            /* Most Checks Already Handled By Component */
            return !newBidMod || !resourceName
        })
    }

    const onPush: OnPushHandler = () => {
        let valid = true

        if (extraDetails) {
            valid = validate(extraDetails)
        }

        return {
            valid,
            pushedData: extraDetails,
        }
    }

    return {
        title,
        entity,
        entityLabel,
        entityName,
        entityPillList,
        entityPillLinkProps,
        entityPillLinkListItems,
        highlightedSegment,
        deviceTableHeaders,
        deviceTableItems,
        donutChartItems,
        domainCurrencyCode,
        proposeIncreaseBid,
        currentBidMod,
        newBidMod,
        formattedSegmentCpa,
        formattedSegmentRoas,
        improvementStatisticsItems,
        lookbackWindow,
        updateBid,
        pushMessages,
        onPush,
        lastUpdated,
        pushActionText,
        isUsingCpa,
        formattedEntityAverage,
        differenceFromEntityAverage,
        differenceFromTarget,
        formattedTarget,
    }
}
