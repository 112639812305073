<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
        @score-history-button-clicked="$emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>

            <!-- Essentail Subscores -->
            <Spacer height="1rem" />
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Essential Ad Extensions</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdown"
                    color="white"
                    size="small"
                    :loading="breakdownLoading"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />
            <div class="ad-extension-area-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="area in essentialAdExtensionSubScores" class="ad-extension-area-item">
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="isLoading"
                            :score="isLoading ? 0 : area.score"
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <Text as="p" size="f-7" weight="500">{{ area.title }}</Text>
                        <Spacer height="0.25rem" />
                        <Text as="p" size="f-8">
                            {{ area.copy }}
                        </Text>
                    </div>
                </div>
            </div>

            <Spacer height="2.5rem" />

            <!-- Supplementary Extension Subscores -->
            <Text as="h6" weight="600">Supplementary Ad Extensions</Text>
            <Spacer height="2rem" />
            <div class="ad-extension-area-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div
                    v-for="area in supplementaryAdExtensionSubScores"
                    class="ad-extension-area-item"
                >
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="isLoading"
                            :score="isLoading ? 0 : area.score"
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <Text as="p" size="f-7" weight="500">{{ area.title }}</Text>
                        <Spacer height="0.25rem" />
                        <Text as="p" size="f-8">
                            {{ area.copy }}
                        </Text>
                    </div>
                </div>
            </div>

            <Spacer height="1.5rem" />

            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Score weighted by impressions. Data collected over the last <b>30 days</b>.
                </Text>
            </ImprovementNote>
        </template>
    </ScorecardSection>
    <!-- Campaign Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="$emit('breakdown-requested')"
        :showPanel="showCampaignBreakdown"
        :closePanel="closeCampaignBreakdown"
        title="Ad Extension Coverage"
        :width="1176"
        :loading="breakdownLoading"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="([campaignName, items, campaignCost], index) in filteredBreakdownTableItems"
                :class="[
                    'campaign-breakdown-container',
                    { 'mb-36': index !== filteredBreakdownTableItems?.length - 1 },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaignName"
                    :currency="domainCurrency"
                    :cost="campaignCost"
                />
                <Spacer height="2.25rem" />
                <oTable
                    :headers="breakdownTableHeaders"
                    :items="items"
                    :fixed-layout="true"
                    :border-radius="20"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                >
                    <template #header.adGroup>
                        <div class="header-cell">Ad Group</div>
                    </template>
                    <template #column.adGroup="cellData">
                        <div class="column-overlay">
                            <Tooltip
                                :content="cellData.value?.length >= 60 ? cellData.value : ''"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="adgroup"
                                    :content="cellData.value"
                                    :tooltip="false"
                                />
                            </Tooltip>
                        </div>
                    </template>
                    <template #column.cost="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.sitelinkTotalCount="cellData">
                        <AdExtensionCoverageBreakdownTableCell
                            :total-count="cellData.value"
                            :customer-level-count="cellData.row.sitelink.customerLevelCount"
                            :campaign-level-count="cellData.row.sitelink.campaignLevelCount"
                            :ad-group-level-count="cellData.row.sitelink.adGroupLevelCount"
                        />
                    </template>
                    <template #column.calloutTotalCount="cellData">
                        <AdExtensionCoverageBreakdownTableCell
                            :total-count="cellData.value"
                            :customer-level-count="cellData.row.callout.customerLevelCount"
                            :campaign-level-count="cellData.row.callout.campaignLevelCount"
                            :ad-group-level-count="cellData.row.callout.adGroupLevelCount"
                        />
                    </template>
                    <template #column.structuredSnippetTotalCount="cellData">
                        <AdExtensionCoverageBreakdownTableCell
                            :total-count="cellData.value"
                            :customer-level-count="
                                cellData.row.structured_snippet.customerLevelCount
                            "
                            :campaign-level-count="
                                cellData.row.structured_snippet.campaignLevelCount
                            "
                            :ad-group-level-count="
                                cellData.row.structured_snippet.adGroupLevelCount
                            "
                        />
                    </template>
                </oTable>
            </div>
            <!-- Show More Button -->
            <div v-if="!showMoreButtonDisabled" class="panel-button-container">
                <oButton
                    color="white"
                    size="medium"
                    @clicked="showMoreTables"
                    :disabled="showMoreButtonDisabled"
                >
                    Show More
                </oButton>
            </div>
        </template>
        <template #footer>
            <oButton @clicked="initiateCsvDownload" size="extra-large"> Download CSV </oButton>
        </template>
    </BreakdownPanel>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue'
import { Scorecard } from '@opteo/types'
import keyBy from 'lodash-es/keyBy'
import sortBy from 'lodash-es/sortBy'
import sumBy from 'lodash-es/sumBy'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { downloadCsv } from '@/lib/globalUtils'
import AdExtensionCoverageBreakdownTableCell from './AdExtensionCoverageBreakdownTableCell.vue'
import SectionHidden from './SectionHidden.vue'
import { getSectionCopy, formatBreakdown } from './utils'
import ScorecardSection from './ScorecardSection.vue'
import ScorecardDonut from './ScorecardDonut.vue'
import BreakdownTableHeader from './BreakdownTableHeader.vue'
import BreakdownPanel from './BreakdownPanel.vue'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Number as NumberVue,
    Roas,
    oButton,
    Tooltip,
    Popout,
    ChartTooltip,
    ColorTag,
    EntityPill,
} from '@opteo/components-next'

export default defineComponent({
    name: 'AdExtensionCoverage',
    components: {
        Text,
        Spacer,
        oTable,
        Money,
        Percent,
        NumberVue,
        Roas,
        SectionHidden,
        ScorecardSection,
        ScorecardDonut,
        oButton,
        BreakdownPanel,
        Tooltip,
        Popout,
        ChartTooltip,
        ColorTag,
        EntityPill,
        AdExtensionCoverageBreakdownTableCell,
        BreakdownTableHeader,
    },
    props: {
        score: {
            type: Number,
        },
        domainName: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        domainCurrency: {
            type: String,
            required: true,
        },
        details: {
            type: Object as PropType<Scorecard.AdExtensionScoreDetails>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
        breakdown: {
            type: Array as PropType<Scorecard.AdExtensionScoreBreakdownItem[]>,
        },
        breakdownLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['score-history-button-clicked', 'breakdown-requested'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'
        const { aboveiPadLandscape } = useMediaQuery()

        // Breakdown Panel
        const showCampaignBreakdown = ref(false)

        const openCampaignBreakdown = () => {
            showCampaignBreakdown.value = true
        }

        const closeCampaignBreakdown = () => {
            resetLimitTables()
            showCampaignBreakdown.value = false
        }
        const breakdownTableHeaders = [
            { key: 'adGroup', text: 'Ad Group', sortable: true, noPadding: true },
            { key: 'cost', text: 'Cost (30d)', width: 148, sortable: true },
            { key: 'sitelinkTotalCount', text: 'Sitelinks', width: 112, sortable: true },
            { key: 'calloutTotalCount', text: 'Callouts', width: 112, sortable: true },
            { key: 'structuredSnippetTotalCount', text: 'Snippets', width: 136, sortable: true },
        ]
        const breakdownTableItems = computed(() =>
            sortBy(
                formatBreakdown(
                    props.breakdown?.map(item => {
                        return {
                            id: item.adGroupRn,
                            adGroup: item.adGroupName,
                            campaign: item.campaignName,
                            cost: item.cost,
                            sitelinkTotalCount: item.extensions.find(
                                extension => extension.type === 'sitelink'
                            )?.totalCount,
                            calloutTotalCount: item.extensions.find(
                                extension => extension.type === 'callout'
                            )?.totalCount,
                            structuredSnippetTotalCount: item.extensions.find(
                                extension => extension.type === 'structured_snippet'
                            )?.totalCount,
                            ...keyBy(item.extensions, extension => extension.type),
                        }
                    }) ?? []
                ).map(row => [row[0], row[1], sumBy(row[1], i => i.cost)] as const),
                ([_, __, cost]) => -cost
            )
        )

        let limitTables = ref(8)

        function showMoreTables() {
            limitTables.value += 8
        }
        function resetLimitTables() {
            limitTables.value = 8
        }

        const filteredBreakdownTableItems = computed(() => {
            return breakdownTableItems.value?.slice(0, limitTables.value) ?? []
        })

        const showMoreButtonDisabled = computed(() => {
            return limitTables.value >= breakdownTableItems?.value?.length
        })

        function initiateCsvDownload() {
            const defaultZeros = {
                customerLevelCount: 0,
                campaignLevelCount: 0,
                adGroupLevelCount: 0,
            }
            const toDownload =
                props.breakdown?.map(item => {
                    const {
                        customerLevelCount: sitelinksAccountLevel,
                        campaignLevelCount: sitelinksCampaignLevel,
                        adGroupLevelCount: sitelinksAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'sitelink') ??
                    defaultZeros

                    const {
                        customerLevelCount: calloutsAccountLevel,
                        campaignLevelCount: calloutsCampaignLevel,
                        adGroupLevelCount: calloutsAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'callout') ??
                    defaultZeros

                    const {
                        customerLevelCount: structuredSnippetsAccountLevel,
                        campaignLevelCount: structuredSnippetsCampaignLevel,
                        adGroupLevelCount: structuredSnippetsAdGroupLevel,
                    } = item.extensions.find(
                        extension => extension.type === 'structured_snippet'
                    ) ?? defaultZeros

                    const {
                        customerLevelCount: callAccountLevel,
                        campaignLevelCount: callCampaignLevel,
                        adGroupLevelCount: callAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'call') ?? defaultZeros

                    const {
                        customerLevelCount: imageAccountLevel,
                        campaignLevelCount: imageCampaignLevel,
                        adGroupLevelCount: imageAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'image') ??
                    defaultZeros

                    const {
                        customerLevelCount: locationAccountLevel,
                        campaignLevelCount: locationCampaignLevel,
                        adGroupLevelCount: locationAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'location') ??
                    defaultZeros

                    const {
                        customerLevelCount: leadFormAccountLevel,
                        campaignLevelCount: leadFormCampaignLevel,
                        adGroupLevelCount: leadFormAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'lead_form') ??
                    defaultZeros

                    const {
                        customerLevelCount: mobileAppAccountLevel,
                        campaignLevelCount: mobileAppCampaignLevel,
                        adGroupLevelCount: mobileAppAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'mobile_app') ??
                    defaultZeros

                    const {
                        customerLevelCount: priceAccountLevel,
                        campaignLevelCount: priceCampaignLevel,
                        adGroupLevelCount: priceAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'price') ??
                    defaultZeros

                    const {
                        customerLevelCount: promotionAccountLevel,
                        campaignLevelCount: promotionCampaignLevel,
                        adGroupLevelCount: promotionAdGroupLevel,
                    } = item.extensions.find(extension => extension.type === 'promotion') ??
                    defaultZeros

                    return {
                        adGroup: item.adGroupName,
                        campaign: item.campaignName,
                        cost: item.cost,
                        sitelinksAccountLevel,
                        sitelinksCampaignLevel,
                        sitelinksAdGroupLevel,
                        calloutsAccountLevel,
                        calloutsCampaignLevel,
                        calloutsAdGroupLevel,
                        structuredSnippetsAccountLevel,
                        structuredSnippetsCampaignLevel,
                        structuredSnippetsAdGroupLevel,
                        imageAccountLevel,
                        imageCampaignLevel,
                        imageAdGroupLevel,
                        locationAccountLevel,
                        locationCampaignLevel,
                        locationAdGroupLevel,
                        callAccountLevel,
                        callCampaignLevel,
                        callAdGroupLevel,
                        leadFormAccountLevel,
                        leadFormCampaignLevel,
                        leadFormAdGroupLevel,
                        mobileAppAccountLevel,
                        mobileAppCampaignLevel,
                        mobileAppAdGroupLevel,
                        priceAccountLevel,
                        priceCampaignLevel,
                        priceAdGroupLevel,
                        promotionAccountLevel,
                        promotionCampaignLevel,
                        promotionAdGroupLevel,
                    }
                }) ?? []

            downloadCsv({
                dataSet: 'ad_extension_coverage_breakdown',
                items: toDownload,
                options: {},
            })
        }

        const essentialAdExtensionSubScores = computed(() => {
            if (!props.details?.extensions) return []

            return [
                {
                    title: 'Sitelinks',
                    copy: 'Sitelinks help maximise all available ad space and aid searchers in navigating to a relevant landing page.',
                    score: props.details.extensions.sitelink.score,
                },
                {
                    title: 'Callouts',
                    copy: 'Callout extensions add additional text to highlight key information and time-limited offers for searchers.',
                    score: props.details.extensions.callout.score,
                },
                {
                    title: 'Structured Snippets',
                    copy: 'Structured snippets provide a unique opportunity to showcase specific attributes to interested searchers.',
                    score: props.details.extensions.structured_snippet.score,
                },
            ]
        })

        const supplementaryAdExtensionSubScores = computed(() => {
            if (!props.details?.extensions) return []

            return [
                {
                    title: 'Image',
                    copy: 'Image extensions add rich complimentary visuals to text ads and can enhance the message of your ads.',
                    score: props.details?.extensions.image.score,
                },
                {
                    title: 'Location',
                    copy: 'Location extensions add your address, a map of your location, and the distance to your business. ',
                    score: props.details?.extensions.location.score,
                },
                {
                    title: 'Call',
                    copy: 'Call extensions allow people to tap or click a button and call your business directly from a search ad.',
                    score: props.details?.extensions.call.score,
                },
                {
                    title: 'Lead Form',
                    copy: 'Lead Form extensions allow searchers to submit their information via a form, directly through Google ads.',
                    score: props.details?.extensions.lead_form.score,
                },
                {
                    title: 'Mobile App',
                    copy: 'Mobile App extensions allow you to provide a link to both your website and app from a single text ad.',
                    score: props.details?.extensions.mobile_app.score,
                },
                {
                    title: 'Price',
                    copy: 'Price extensions allow you to show more information about your offerings for Search Network text ads.',
                    score: props.details?.extensions.price.score,
                },
                {
                    title: 'Promotion',
                    copy: 'Promotion extensions let you highlight sales and promotions below your Search Network text ads. ',
                    score: props.details?.extensions.promotion.score,
                },
            ]
        })

        const textOptions = {
            0: [
                `No campaigns in your account have ad extensions applied.`,
                `Extensions visually enhance search ads and increase the amount of screen real estate your ads occupy. This can help attract more customers to your website, but in many cases, ad extensions can be overlooked when writing new ads. Ensuring the consistent use of extensions across your campaigns can help improve visibility and engagement—with complete coverage being ideal.`,
                `In addition to implementing ad extensions across all campaigns, aim to incorporate a variety of extension types to provide Google with enough options to test for profitable combinations.`,
            ],
            1: [
                `Very few campaigns in your account have ad extensions applied.`,
                `Extensions visually enhance search ads and increase the amount of screen real estate your ads occupy. This can help attract more customers to your website, but in many cases, ad extensions can be overlooked when writing new ads. Ensuring the consistent use of extensions across your campaigns can help improve visibility and engagement—with complete coverage being ideal.`,
                `In addition to implementing ad extensions across all campaigns, aim to incorporate a variety of extension types to provide Google with enough options to test for profitable combinations.`,
            ],
            30: [
                `Some campaigns in your account have ad extensions applied, however many do not. Your ads are frequently served without extensions, which could increase costs and lower CTR.`,
                `Extensions visually enhance search ads and increase the amount of screen real estate your ads occupy. This can help attract more customers to your website, but in many cases, ad extensions can be overlooked when writing new ads. Ensuring the consistent use of extensions across your campaigns can help improve visibility and engagement—with complete coverage being ideal.`,
                `In addition to implementing ad extensions across all campaigns, aim to incorporate a variety of extension types to provide Google with enough options to test for profitable combinations.`,
            ],
            70: [
                `Your account is making good use of ad extensions.`,
                `Extensions visually enhance search ads and increase the amount of screen real estate your ads occupy. This can help attract more customers to your website, but in many cases, ad extensions can be overlooked when writing new ads. Ensuring the consistent use of extensions across your campaigns can help improve visibility and engagement—with complete coverage being ideal.`,
                `In addition to implementing ad extensions across all campaigns, aim to incorporate a variety of extension types to provide Google with enough options to test for profitable combinations.`,
            ],
        }

        const sectionCopy = computed(() => {
            return getSectionCopy(textOptions, props.score ?? 0)
        })

        function cleanCapitalise(text: string) {
            return text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ')
        }

        return {
            sectionType: Scorecard.SectionTypes.adExtension,
            sectionName: Scorecard.SectionNames.adExtension,
            isPdf,
            essentialAdExtensionSubScores,
            supplementaryAdExtensionSubScores,
            sectionCopy,
            cleanCapitalise,

            // Breakdown Panel
            showCampaignBreakdown,
            openCampaignBreakdown,
            closeCampaignBreakdown,
            breakdownTableHeaders,
            breakdownTableItems,
            initiateCsvDownload,
            aboveiPadLandscape,
            showMoreTables,
            resetLimitTables,
            filteredBreakdownTableItems,
            showMoreButtonDisabled,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ad-extension-area-container {
    @include container;
    @include br-20;
    @include w-100;
}
.ad-extension-area-item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-bottom: 1px solid #f7f7f9;
}
.ad-extension-area-item:last-child {
    border-bottom: none;
}
:deep(.improvement-note) {
    @include br-20;
}

// Campaign Breakdown Panel
.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}
.title-tag {
    @include container;
    @include flex-center;
    @include br-12;
    @include ph-16;
    height: 2.75rem;
}
.header-cell {
    @include pl-24;
}
.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-36;
}
</style>
