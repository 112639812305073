<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        section-type="landingPages"
        section-name="Landing Pages"
        :mode="mode"
        @score-history-button-clicked="emit('scoreHistoryButtonClicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">Link Summary</Text>
            <Spacer height="2rem" />
            <div class="key-statistics-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div class="key-statistic-item">
                    <Text as="span" size="f-8">Destination URLs</Text>
                    <Spacer height="0.375rem" />
                    <div class="statistic">
                        <Text as="h5" weight="600" style="letter-spacing: -0.06rem">
                            <NumberVue :value="totalNumberOfUrls" />
                        </Text>
                        <Spacer width="0.75rem" />
                    </div>
                </div>
                <div class="key-statistic-item">
                    <Text
                        as="span"
                        size="f-8"
                        :color="totalNumberOfBrokenUrls > 0 ? 'red' : 'black'"
                    >
                        Broken Links
                    </Text>
                    <Spacer height="0.375rem" />
                    <div class="statistic">
                        <Text
                            as="h5"
                            weight="600"
                            style="letter-spacing: -0.06rem"
                            :color="totalNumberOfBrokenUrls > 0 ? 'red' : 'black'"
                        >
                            <NumberVue :value="totalNumberOfBrokenUrls" />
                        </Text>
                        <Spacer width="0.75rem" />
                        <WarnIcon
                            v-if="totalNumberOfBrokenUrls > 0"
                            class="statistic-icon"
                            translucent
                        />
                        <CheckIcon v-else class="statistic-icon" translucent />
                    </div>
                </div>
            </div>
            <Spacer height="2.5rem" />

            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Top URLs by Cost</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="showUrlBreakdown = true"
                    :loading="breakdownLoading"
                    color="white"
                    size="small"
                >
                    View All Destination URLs
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <div class="data-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <DonutChart
                    :showKeys="false"
                    :items="formatDonutChartData(pieChartData ?? [])"
                    :metric="{ label: 'Cost', dataType: 'percentage' }"
                />
                <oTable
                    class="table"
                    :headers="topUrlsTableHeaders"
                    :items="topLandingPageUrlsTableItems"
                    :fixed-layout="true"
                    :border-radius="0"
                    no-shadow
                    responsive-mode="columns"
                    responsive-breakpoint="767px"
                >
                    <template #header.url>
                        <div class="header-cell">URL</div>
                    </template>
                    <template #column.url="cellData">
                        <div class="column-overlay">
                            <EntityPill type="url" :content="cellData.value" />
                        </div>
                    </template>

                    <template #column.cost="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
                    </template>

                    <template #column.conversions="cellData">
                        <NumberVue :value="cellData.value ?? 0" :decimal-places="1" />
                    </template>

                    <template #column.conversionsValue="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
                    </template>

                    <template #column.cpa="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
                    </template>

                    <template #column.roas="cellData">
                        <Roas :value="cellData.value ?? 0" />
                    </template>
                </oTable>
            </div>
            <Spacer height="2.5rem" />

            <Text as="h6" weight="600">Landing Page Health</Text>
            <Spacer height="2rem" />
            <div class="sub-scores-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="row in landingPagesSubScores" class="item">
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="isLoading || row.score === null"
                            :score="isLoading || row.score === null ? 0 : row.score"
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <Text
                            as="p"
                            size="f-7"
                            weight="500"
                            :color="row.score === null ? 'gray' : 'black'"
                            >{{ row.title }}</Text
                        >
                        <Spacer height="0.25rem" />
                        <Text as="p" size="f-8" :color="row.score === null ? 'gray' : 'black'">
                            {{ row.copy }}
                        </Text>
                    </div>
                </div>
            </div>

            <!-- Campaign Breakdown Panel -->
            <BreakdownPanel
                @breakdown-requested="emit('breakdownRequested')"
                :show-panel="showUrlBreakdown"
                :close-panel="closeUrlBreakdown"
                :loading="breakdownLoading"
                title="Top URLs by Cost"
                :width="1168"
            >
                <template #title>
                    <ColorTag color="blue" title="All Destination URLs" />
                </template>
                <template #content>
                    <oTable
                        :headers="urlBreakdownTableHeaders"
                        :items="urlBreakdownTableItems"
                        :fixed-layout="true"
                        :border-radius="20"
                        :class="{ 'pdf-shadow-fix': isPdf }"
                    >
                        <template #header.url>
                            <div class="header-cell">URL</div>
                        </template>
                        <template #column.url="cellData">
                            <div class="column-overlay">
                                <Tooltip
                                    :content="cellData.value.length > 20 ? cellData.value : ''"
                                    :offset="[0, 8]"
                                    placement="top-start"
                                    :max-width="1060"
                                >
                                    <EntityPill
                                        type="url"
                                        :content="cellData.value"
                                        :tooltip="false"
                                    />
                                </Tooltip>
                            </div>
                        </template>
                        <template #column.cost="cellData">
                            <Money :currency="domainCurrency" :value="cellData.value" />
                        </template>
                        <template #column.conversions="cellData">
                            <NumberVue :value="cellData.value" :decimal-places="1" />
                        </template>
                        <template #column.conversionsValue="cellData">
                            <Money :currency="domainCurrency" :value="cellData.value" />
                        </template>
                        <template #column.cpa="cellData">
                            <Money :currency="domainCurrency" :value="cellData.value" />
                        </template>
                        <template #column.roas="cellData">
                            <Roas :value="cellData.value" />
                        </template>
                        <template #column.isBroken="cellData">
                            <Text
                                as="p"
                                size="f-8"
                                :color="
                                    cellData.value === 'Active'
                                        ? 'green'
                                        : cellData.value === 'Broken'
                                          ? 'red'
                                          : 'gray'
                                "
                                :weight="cellData.value === 'No Data' ? '400' : '500'"
                            >
                                {{ cellData.value }}
                            </Text>
                        </template>
                        <template #column.redirect="cellData">
                            <Text
                                as="p"
                                size="f-8"
                                :color="cellData.value === 'No Data' ? 'gray' : 'black'"
                            >
                                {{ cellData.value }}
                            </Text>
                        </template>
                        <template #column.https="cellData">
                            <CheckIcon v-if="cellData.value === true" translucent />
                            <WarnIcon v-else color="red" translucent />
                        </template>
                        <template #column.speedScore="cellData">
                            <div class="score-table-cell">
                                <ScorecardDonut
                                    :score="cellData.value"
                                    :compact="true"
                                    :width="22"
                                    :strokeWidth="2"
                                    hide-score
                                    :hidden="!cellData.value || cellData.value === null"
                                />
                                <Text
                                    as="p"
                                    size="f-8"
                                    weight="500"
                                    :color="
                                        cellData.value ? scoreColor(cellData.value).name : 'gray'
                                    "
                                >
                                    <Percent
                                        v-if="cellData.value && cellData.value !== null"
                                        :value="cellData.value / 100"
                                        :decimal-places="0"
                                    />
                                    <span v-else>N/A</span>
                                </Text>
                            </div>
                        </template>
                        <template #column.mobileFriendliness="cellData">
                            <div class="score-table-cell">
                                <ScorecardDonut
                                    :score="cellData.value"
                                    :compact="true"
                                    :width="22"
                                    :strokeWidth="2"
                                    hide-score
                                    :hidden="!cellData.value || cellData.value === null"
                                />
                                <Text
                                    as="p"
                                    size="f-8"
                                    weight="500"
                                    :color="
                                        cellData.value ? scoreColor(cellData.value).name : 'gray'
                                    "
                                >
                                    <Percent
                                        v-if="cellData.value && cellData.value !== null"
                                        :value="cellData.value / 100"
                                        :decimal-places="0"
                                    />
                                    <span v-else>N/A</span>
                                </Text>
                            </div>
                        </template>
                    </oTable>
                </template>
                <template #footer>
                    <oButton
                        @clicked="
                            downloadCsv({
                                dataSet: 'landing-pages',
                                items: urlBreakdownTableItems,
                                options: {
                                    initialColumnHeaders: [
                                        'URL',
                                        'Cost',
                                        'Conversions',
                                        'Conversions Value',
                                        'CPA',
                                        'ROAS',
                                        'Is Broken',
                                        'Redirect',
                                        'HTTPS',
                                        'Speed Score',
                                        'Mobile Friendliness',
                                    ],
                                },
                            })
                        "
                        size="extra-large"
                    >
                        Download CSV
                    </oButton>
                </template>
            </BreakdownPanel>
        </template>
    </ScorecardSection>
</template>

<script lang="ts" setup>
import { Scorecard } from '@opteo/types'
import ScorecardSection from './ScorecardSection.vue'
import { computed, ref } from 'vue'
import { getSectionCopy } from './utils'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { scoreColor } from '@/composition/toolkit/scorecard/utils'
import orderBy from 'lodash-es/orderBy'
import sumBy from 'lodash-es/sumBy'
import { formatDonutChartData } from './utils'
import { downloadCsv } from '@/lib/globalUtils'
import BreakdownPanel from './BreakdownPanel.vue'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Roas,
    Number as NumberVue,
    oButton,
    Tooltip,
    Panel,
    EntityPill,
    DonutChart,
    CheckIcon,
    WarnIcon,
    ColorTag,
} from '@opteo/components-next'

import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'

const props = defineProps<{
    score: number
    invalid: boolean
    isLoading: boolean
    domainName: string
    isUsingCpa: boolean
    domainCurrency: string
    mode: 'live' | 'pdf' | 'app'
    details: Scorecard.LandingPagesScoreSection['details']
    breakdown?: Scorecard.LandingPagesScoreSection['breakdown']
    breakdownLoading?: boolean
}>()

const emit = defineEmits<{
    scoreHistoryButtonClicked: []
    breakdownRequested: []
}>()

const isPdf = props.mode === 'pdf'
const { aboveiPadLandscape } = useMediaQuery()

const textOptions = {
    0: [
        `Healthy landing pages play a crucial role in the success of your campaigns. Avoiding broken links, redirects, slow loading times, and other conversion-blocking issues can help ensure searchers have a smooth path to conversion on your site.`,
        `Check for broken links and remove cumbersome redirects where possible. Secure, fast-loading, mobile-friendly landing pages can make all the difference when it comes to reducing bounce rates, increasing quality scores, and improving conversion rates.`,
    ],
    70: [
        `Healthy landing pages play a crucial role in the success of your campaigns. Avoiding broken links, redirects, slow loading times, and other conversion-blocking issues can help ensure searchers have a smooth path to conversion on your site.`,
        `While your landing pages are in good health, there are always opportunities to refine and improve performance. Consider copy adjustments (with a focus on core search terms), optimising page load times, and any additional user experience enhancements.`,
    ],
}

const sectionCopy = computed(() => {
    return getSectionCopy(textOptions, props.score ?? 0)
})

// Link Summary
const totalNumberOfUrls = computed(() => props.details.totalPageCount)
const totalNumberOfBrokenUrls = computed(() => props.details.totalBrokenPageCount)

// Top Urls By Cost
const topUrlsTableHeaders = computed(() => {
    const performanceSpecificHeaders = props.isUsingCpa
        ? [
              { key: 'conversions', text: 'Conv.', width: 72, noPadding: true },
              { key: 'cpa', text: 'CPA', width: 78, noPadding: true },
          ]
        : [
              { key: 'conversionsValue', text: 'Value', width: 72, noPadding: true },
              { key: 'roas', text: 'ROAS', width: 78, noPadding: true },
          ]

    return [
        { key: 'url', text: 'URL', noPadding: true },
        { key: 'cost', text: 'Cost', width: 82, noPadding: true },
        ...performanceSpecificHeaders,
    ]
})

const topLandingPageUrlsTableItems = computed(() => {
    const topSpendingUrls = orderBy(props.details.topPages, ['cost'], ['desc'])

    return topSpendingUrls.map(item => {
        return {
            url: item.url,
            cost: item.cost,
            conversions: item.conversions,
            cpa: item.cost === 0 && item.conversions === 0 ? 0 : item.cost / item.conversions,
            conversionsValue: item.conversionsValue,
            roas:
                item.conversionsValue === 0 && item.cost === 0
                    ? 0
                    : item.conversionsValue / item.cost,
        }
    })
})

const pieChartData = computed(() => {
    const top5Urls = topLandingPageUrlsTableItems.value.map(({ url, cost }) => ({ url, cost }))
    const otherCost = props.details.totalCostOfNonTopPages

    let chartItems = otherCost ? [...top5Urls, { url: 'Others', cost: otherCost }] : top5Urls
    let formattedChartItems = chartItems.map(({ url, cost }) => ({ y: cost, name: url }))

    const sumAllChartItems = sumBy(formattedChartItems, 'y')
    const percentages = formattedChartItems.map(({ name, y }) => ({
        name,
        y: y / sumAllChartItems,
    }))

    return percentages
})

// Sub Scores
const isBrokenSubScore = computed(() => {
    return props.details.subScores.brokenLinkScore
})
const redirectsSubScore = computed(() => {
    return props.details.subScores.redirectScore
})
const httpsSubScore = computed(() => {
    return props.details.subScores.httpsUsageScore
})
const speedSubScore = computed(() => {
    return props.details.subScores.speedScore
})
const mobileFriendlinessSubScore = computed(() => {
    return props.details.subScores.mobileFriendlyScore
})

const landingPagesSubScores = [
    {
        title: 'Broken Links',
        copy:
            isBrokenSubScore.value === 100
                ? 'Your landing pages have been checked for common error code responses including 404, 500, 502, 504, and so on. No significant issues were discovered.'
                : 'Your landing pages have been checked for common error code responses including 404, 500, 502, 504, and so on. A number of issues were discovered.',
        score: isBrokenSubScore.value,
    },
    {
        title: 'Redirects',
        copy: `Redirects can increase load times, lead to exclusion from Google's search engine results pages, and send users to unexpected locations after clicking your ad.`,
        score: redirectsSubScore.value,
    },
    {
        title: 'Use of HTTPS',
        copy:
            httpsSubScore.value === 100
                ? 'Your destination URLs use HTTPS, ensuring secure data transfer between searchers and your website.'
                : 'Some destination URLs are using unsecured protocols, this can trigger warnings for users visiting these pages, and thereby significantly reduce conversion rates.',
        score: httpsSubScore.value,
    },
    {
        title: 'Speed Score',
        copy: 'Pages with high speed scores typically result in lower bounce rates and higher overall conversion rates.',
        score: speedSubScore.value,
    },
    {
        title: 'Mobile-friendliness',
        copy: 'Mobile-friendly websites remain accessible across all device types, increasing the likelihood of conversions.',
        score: mobileFriendlinessSubScore.value,
    },
]

// Breakdown Panel
const showUrlBreakdown = ref(false)
const closeUrlBreakdown = () => {
    showUrlBreakdown.value = false
}

const urlBreakdownTableHeaders = computed(() => {
    const performanceSpecificHeaders = props.isUsingCpa
        ? [
              { key: 'conversions', text: 'Conv.', width: 104, noPadding: true, sortable: true },
              { key: 'cpa', text: 'CPA', width: 106, noPadding: true, sortable: true },
          ]
        : [
              {
                  key: 'conversionsValue',
                  text: 'Value',
                  width: 104,
                  noPadding: true,
                  sortable: true,
              },
              { key: 'roas', text: 'ROAS', width: 106, noPadding: true, sortable: true },
          ]

    return [
        { key: 'url', text: 'URL', noPadding: true, sortable: true },
        { key: 'cost', text: 'Cost', width: 98, noPadding: true, sortable: true },
        ...performanceSpecificHeaders,
        { key: 'isBroken', text: 'Status', width: 100, noPadding: true, sortable: true },
        { key: 'redirect', text: 'Redirect', width: 110, noPadding: true, sortable: true },
        { key: 'https', text: 'HTTPS', width: 100, noPadding: true, sortable: true },
        { key: 'speedScore', text: 'Speed', width: 110, noPadding: true, sortable: true },
        { key: 'mobileFriendliness', text: 'Mobile', width: 100, noPadding: true, sortable: true },
    ]
})
const urlBreakdownTableItems = computed(() => {
    const topSpendingUrls = orderBy(props.breakdown?.allPages, ['cost'], ['desc'])

    return topSpendingUrls.map(item => {
        return {
            url: item.url,
            cost: item.cost,
            conversions: item.conversions,
            cpa: item.cost === 0 && item.conversions === 0 ? 0 : item.cost / item.conversions,
            conversionsValue: item.conversionsValue,
            roas:
                item.conversionsValue === 0 && item.cost === 0
                    ? 0
                    : item.conversionsValue / item.cost,
            isBroken:
                item.isBroken === true ? 'Broken' : item.isBroken === false ? 'Active' : 'No Data',
            redirect:
                item.isRedirect === true
                    ? 'Redirect'
                    : item.isRedirect === false
                      ? 'Direct'
                      : 'No Data',
            https: item.usesHttps,
            speedScore: item.speedScore,
            mobileFriendliness: item.mobileFriendlyScore,
        }
    })
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.key-statistics-container {
    @include container;
    @include br-20;
    @include w-100;
    @include grid;
    grid-template-columns: 1fr 1fr;
}
.key-statistic-item {
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid #f7f7f9;
    @include relative;
}
.key-statistic-item:last-child,
.key-statistic-item:nth-last-child(-n + 2) {
    border-bottom: none;
}
.key-statistic-item:nth-child(odd) {
    border-right: 1px solid #f7f7f9;
}
.statistic {
    @include flex;
    @include items-center;
}
// .statistic-icon {
//     @include absolute;
//     bottom: 19px;
//     right: 19px;
// }

.data-container {
    @include container;
    @include br-20;
    overflow: hidden;

    & .table {
        border-top: 1px solid;
        @include opteo-border;
        // margin-top: 32px;
    }
}

.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.header-cell {
    @include pl-24;
}

.sub-scores-container {
    @include container;
    @include br-20;
    @include w-100;
}
.sub-scores-container .item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-bottom: 1px solid #f7f7f9;
}
.sub-scores-container .item:last-child {
    border-bottom: none;
}

.score-table-cell {
    @include flex;
    @include items-center;
    gap: 0.5rem;
}

@media screen and (max-width: $mq-767-max) {
    .header-cell {
        padding-left: unset;
    }
    .column-overlay {
        height: unset;
        padding-left: unset;
        overflow: unset;
        @include w-100;
        min-width: unset;
    }
    .column-overlay::after {
        display: none;
    }
}
</style>
