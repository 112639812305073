<template>
    <ScorecardSection
        :score="props.score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
        @score-history-button-clicked="emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">Campaign Summary</Text>
            <Spacer height="2rem" />
            <div class="summary-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div class="chart-area">
                    <ScatterPointChart
                        :items="chartItems.items"
                        :cut-off="chartItems.cutOff"
                        :axis="chartItems.axis"
                        :metric="chartItems.metric"
                        :hideAxisLabels="true"
                        :remove-placeholder-container="true"
                    />
                </div>
                <div class="key-statistics-container">
                    <div
                        v-for="statistic in keyStatistics"
                        :key="statistic.id"
                        class="key-statistic-item"
                    >
                        <Text as="span" size="f-8">{{ statistic.name }}</Text>
                        <Spacer height="0.375rem" />
                        <div class="statistic">
                            <Text as="h5" weight="600" style="letter-spacing: -0.06rem">{{
                                statistic.value
                            }}</Text>
                            <Spacer width="0.75rem" />
                            <Delta
                                :delta="statistic.delta"
                                :reverse="statistic.deltaReverse"
                                :decimal-places="statistic.delta >= 1 ? 0 : 2"
                                :threshold="0"
                                style="transform: translateY(1px)"
                            />
                        </div>
                    </div>
                </div>
                <div class="note-container">
                    <Text size="f-9" color="gray">
                        Data from the last <b>90 days</b>. Compared with the previous
                        <b>90 days</b>.
                    </Text>
                </div>
            </div>

            <Spacer height="2rem" />

            <Text as="h6" weight="600">Spend Reallocation Summary</Text>
            <Spacer height="2rem" />
            <div class="summary-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div class="key-statistics-container">
                    <div
                        v-for="statistic in reallocationSummary"
                        :key="statistic.id"
                        class="key-statistic-item"
                    >
                        <Text as="span" size="f-8">{{ statistic.name }}</Text>
                        <Spacer height="0.375rem" />
                        <div class="statistic">
                            <Text as="h5" weight="600" style="letter-spacing: -0.06rem">{{
                                statistic.value
                            }}</Text>
                            <Spacer width="0.75rem" />
                            <Delta
                                v-if="statistic.delta"
                                :delta="statistic.delta"
                                :reverse="statistic.deltaReverse"
                                :decimal-places="statistic.delta >= 1 ? 0 : 2"
                                :threshold="0"
                                style="transform: translateY(1px)"
                            />
                            <Text v-else as="p" size="f-7" weight="600" color="green">
                                {{ statistic.spend }}/day
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <Spacer height="2rem" />

            <div class="sub-header">
                <Text as="h6" weight="600">Over Allocated Campaigns</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="showCampaignBreakdown = true"
                    :loading="breakdownLoading"
                    color="white"
                    size="small"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <CampaignSpendAllocationTable
                :headers="campaignSpendAllocationHeaders"
                :items="overAllocatedCampaignsItems"
                :is-using-cpa="isUsingCpa"
                :domain-currency="domainCurrency"
                :mode="mode"
                :is-summary-table="true"
            />
            <Spacer height="2rem" />

            <div class="sub-header">
                <Text as="h6" weight="600">Under Allocated Campaigns</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="showCampaignBreakdown = true"
                    :loading="breakdownLoading"
                    color="white"
                    size="small"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <CampaignSpendAllocationTable
                :headers="campaignSpendAllocationHeaders"
                :items="underAllocatedCampaignsItems"
                :is-using-cpa="isUsingCpa"
                :domain-currency="domainCurrency"
                :mode="mode"
                :is-summary-table="true"
            />
            <!-- Campaign Breakdown Panel -->
            <BreakdownPanel
                @breakdown-requested="() => emit('breakdown-requested')"
                :show-panel="showCampaignBreakdown"
                :close-panel="closeCampaignBreakdown"
                :loading="breakdownLoading"
                title="Campaign Spend"
                :width="1176"
            >
                <template #title>
                    <ColorTag color="blue" title="Campaign Breakdown" />
                </template>
                <template #content>
                    <CampaignSpendAllocationTable
                        :headers="breakdownTableHeaders"
                        :items="filteredBreakdownTableItems"
                        :is-using-cpa="isUsingCpa"
                        :domain-currency="domainCurrency"
                        :mode="mode"
                    />
                    <!-- Show More Button -->
                    <div v-if="!showMoreButtonDisabled" class="panel-button-container">
                        <oButton
                            color="white"
                            size="medium"
                            @clicked="showMoreTables"
                            :disabled="showMoreButtonDisabled"
                        >
                            Show More
                        </oButton>
                    </div>
                </template>
                <template #footer>
                    <oButton @clicked="downloadSpendAllocationReport()" size="extra-large">
                        Download Analysis
                    </oButton>
                </template>
            </BreakdownPanel>
        </template>
    </ScorecardSection>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import ExcelJS from 'exceljs'
import orderBy from 'lodash-es/orderBy'
import { Currency, Scorecard } from '@opteo/types'
import ScorecardSection from './ScorecardSection.vue'
import { getSectionCopy, formatBreakdown } from './utils'
import useMediaQuery from '@/composition/global/useMediaQuery'
import BreakdownPanel from './BreakdownPanel.vue'
import CampaignSpendAllocationTable from './CampaignSpendAllocationTable.vue'

// Merge from LP/ALignment section
import { adjustWidthsToFitText, downloadExcelFile } from '@/lib/globalUtils/downloadExcelFile'

import {
    Text,
    Spacer,
    Number as NumberVue,
    oButton,
    ColorTag,
    useMoney,
    useNumber,
    useRoas,
    usePercent,
    Delta,
    ScatterPointChart,
    Tooltip,
} from '@opteo/components-next'
import ImprovementNote from '@/components/improvement/ImprovementNote.vue'

const emit = defineEmits(['score-history-button-clicked', 'breakdown-requested'])
const props = defineProps<{
    score: number
    invalid: boolean
    isLoading: boolean
    domainName?: string
    isUsingCpa: boolean
    domainCurrency: string
    mode: 'live' | 'pdf' | 'app'
    details: Scorecard.SpendAllocationSection['details']
    breakdown?: Scorecard.SpendAllocationSection['breakdown']
    breakdownLoading?: boolean
}>()

const sectionType = Scorecard.SectionTypes.spendAllocation ?? 'spendAllocation'
const sectionName = Scorecard.SectionNames.spendAllocation ?? 'Campaign Spend'

const isPdf = props.mode === 'pdf'
const { aboveiPadLandscape } = useMediaQuery()
const textOptions = {
    0: [
        `By focusing your spend on top-performing campaigns, you can drive conversions without overspending. This analysis identifies any potential inefficiencies in your budget allocation, ensuring resources are directed towards campaigns that perform.`,
        `Explore opportunities to shift spend strategically—scaling up your most successful campaigns, cutting back on campaigns where performance lags behind, and maximising your overall return on investment without increasing your overall spend.`,
    ],
}
const sectionCopy = computed(() => {
    return getSectionCopy(textOptions, props.score ?? 0)
})

const chartItems = computed(() => {
    const items = props.details.chartData
    if (props.isUsingCpa)
        return {
            items,
            axis: {
                y: { label: 'Cost', dataType: 'money', currency: props.domainCurrency },
                x: { label: 'Conversions', dataType: 'number' },
            },
            metric: {
                label: 'CPA',
                dataType: 'money',
                currency: props.domainCurrency,
                inverted: true,
            },
            cutOff: props.details.avgCpa,
        }
    else
        return {
            items,
            axis: {
                x: { label: 'Cost', dataType: 'money', currency: props.domainCurrency },
                y: { label: 'Conversion Value', dataType: 'money', currency: props.domainCurrency },
            },
            metric: { label: 'ROAS', dataType: 'percentage' },
            cutOff: props.details.avgRoas,
        }
})

const keyStatistics = computed(
    (): {
        id: string
        name: string
        value: string | number
        delta: number
        deltaReverse: boolean
    }[] => [
        {
            id: 'activeCampaigns',
            name: 'Active Campaigns',
            value: useNumber({
                value: props.details.activeCampaigns,
            }).displayValue.value,
            delta: props.details.activeCampaignsDelta ?? 0,
            deltaReverse: false,
        },
        {
            id: 'spend',
            name: 'Total Spend',
            value: useMoney({
                value: props.details.totalSpend,
                currency: props.domainCurrency,
                compact: props.details.totalSpend >= 100000 ? true : false,
            }).displayValue.value,
            delta: props.details.spendDelta ?? 0,
            deltaReverse: true,
        },
        props.isUsingCpa
            ? {
                  id: 'conversions',
                  name: 'Conversions',
                  value: useNumber({
                      value: props.details.totalConversions,
                  }).displayValue.value,
                  delta: props.details.conversionsDelta ?? 0,
                  deltaReverse: false,
              }
            : {
                  id: 'conversionsValue',
                  name: 'Conversion Value',
                  value: useMoney({
                      value: props.details.totalConvValue,
                      currency: props.domainCurrency,
                      compact: props.details.totalConvValue >= 100000 ? true : false,
                  }).displayValue.value,
                  delta: props.details.conversionsValueDelta ?? 0,
                  deltaReverse: false,
              },
        props.isUsingCpa
            ? {
                  id: 'cpa',
                  name: 'Average CPA',
                  value: useMoney({
                      value: props.details.avgCpa,
                      currency: props.domainCurrency,
                  }).displayValue.value,
                  delta: props.details.cpaDelta ?? 0,
                  deltaReverse: true,
              }
            : {
                  id: 'roas',
                  name: 'Average ROAS',
                  value: useRoas({
                      value: props.details.avgRoas,
                  }).displayValue.value,
                  delta: props.details.roasDelta ?? 0,
                  deltaReverse: false,
              },
    ]
)

const reallocationSummary = computed(
    (): {
        id: string
        name: string
        value: string | number
        spend?: string
        delta?: number
        deltaReverse?: boolean
    }[] => [
        {
            id: 'reallocation',
            name: 'Suggested Reallocation',
            value: usePercent({
                value: Math.abs(props.details.suggestedDailyReallocation.percent),
                decimalPlaces: 1,
            }).displayValue.value,
            spend: useMoney({
                value: Math.abs(props.details.suggestedDailyReallocation.daily),
                currency: props.domainCurrency,
                compact:
                    Math.abs(props.details.suggestedDailyReallocation.daily) >= 100 ? true : false,
            }).displayValue.value,
        },
        props.isUsingCpa
            ? {
                  id: 'estimatedGain',
                  name: 'Est. Conversion Gain (Daily)',
                  value: useNumber({
                      value: props.details.estimatedConvGainDaily,
                  }).displayValue.value,
                  delta: props.details.estimatedConvGainDailyDelta ?? 0,
                  deltaReverse: false,
              }
            : {
                  id: 'estimatedValueGain',
                  name: 'Est. Value Gain (Daily)',
                  value: useMoney({
                      value: props.details.estimatedConvValueGainDaily,
                      currency: props.domainCurrency,
                      compact: props.details.estimatedConvValueGainDaily >= 100000 ? true : false,
                  }).displayValue.value,
                  delta: props.details.estimatedConvValueGainDailyDelta ?? 0,
                  deltaReverse: false,
              },
    ]
)

// Breakdown Panel
const showCampaignBreakdown = ref(false)
const closeCampaignBreakdown = () => {
    showCampaignBreakdown.value = false
    resetLimitTables()
}

// Show more button
let limitTables = ref(12)
function showMoreTables() {
    limitTables.value += 12
}
function resetLimitTables() {
    limitTables.value = 12
}
const showMoreButtonDisabled = computed(() => {
    return limitTables.value >= campaignBreakdownTableItems.value.length
})
const filteredBreakdownTableItems = computed(() => {
    return campaignBreakdownTableItems.value?.slice(0, limitTables.value) ?? []
})

const breakdownTableHeaders = computed(
    (): {
        key: string
        text: string
        width?: number
        sortable?: boolean
        noPadding?: boolean
    }[] => {
        const performanceSpecificHeaders = props.isUsingCpa
            ? [
                  {
                      key: 'convPercent',
                      text: 'Conv. %',
                      width: 100,
                      sortable: true,
                      noPadding: true,
                  },
                  {
                      key: 'costConvDiff',
                      text: 'Diff.',
                      width: 100,
                      sortable: true,
                      noPadding: true,
                  },
                  { key: 'cpa', text: 'CPA', width: 100, sortable: true, noPadding: true },
                  { key: 'cpaDiff', text: 'vs Avg.', width: 108, sortable: true, noPadding: true },
              ]
            : [
                  {
                      key: 'valuePercent',
                      text: 'Value %',
                      width: 108,
                      sortable: true,
                      noPadding: true,
                  },
                  {
                      key: 'costValueDiff',
                      text: 'Diff.',
                      width: 108,
                      sortable: true,
                      noPadding: true,
                  },
                  { key: 'roas', text: 'ROAS', width: 100, sortable: true, noPadding: true },
                  { key: 'roasDiff', text: 'vs Avg.', width: 108, sortable: true, noPadding: true },
              ]

        return [
            { key: 'campaignName', text: 'Campaign', sortable: true, noPadding: true },
            { key: 'costPercent', text: 'Cost %', width: 108, sortable: true, noPadding: true },
            ...performanceSpecificHeaders,
            { key: 'action', text: 'Rec. Action', width: 208, sortable: true, noPadding: true },
        ]
    }
)

//todo: use Scorecard.CampaignsSpendMetrics type to do this once types are merged
const campaignBreakdownTableItems = computed(
    (): {
        campaignName: string
        campaignId: number
        campaignGroupName: string
        costPercent: number
        convPercent: number
        valuePercent: number
        costConvDiff: number
        costValueDiff: number
        cpa: number
        cpaDiff: number
        roas: number
        roasDiff: number
        action: string
        cost: number
        searchImpressionsShare: number
        shareLostToBudget: number
        shareLostToRank: number
        dailyBudget: number | null
        avgDailySpend: number
        suggestedDailySpend: number
        estimatedConvChange: number
        estimatedConvValueChange: number
        conversions: number
        conversionsValue: number
        campaignSharedBudgetName: string | null
        isSharedBudget: boolean
    }[] => {
        const campaignBreakdown = orderBy(
            props.breakdown?.allocatedCampaignsSpend,
            ['cost'],
            ['desc']
        )

        if (!campaignBreakdown?.length) return []

        const averageCpa = props.details.avgCpa
        const averageRoas = props.details.avgRoas

        return campaignBreakdown.map(campaign => {
            const {
                cost,
                costPercent,
                conversionsPercent,
                searchImpressionsShare,
                cpa,
                roas,
                conversionsValuePercent,
                campaignName,
                campaignGroupName,
                campaignId,
                diff, //original diff based on current metrics
                suggestedDailySpendPercent, //final diff based on reallocation of budgets
                shareLostToBudget,
                shareLostToRank,
                dailyBudget,
                avgDailySpend,
                suggestedDailySpend,
                estimatedConvChange, //similar to diff and suggestedDailySpendPercent
                rollingEstimatedConvChange,
                estimatedConvValueChange,
                rollingEstimatedConvValueChange,
                conversions,
                conversionsValue,
                campaignSharedBudgetName,
                isSharedBudget,
            } = campaign

            const cpaDiff = (cpa - averageCpa) / averageCpa
            const roasDiff = (roas - averageRoas) / averageRoas

            const costConvDiff = (conversionsPercent - costPercent) / costPercent
            const costValueDiff = (conversionsValuePercent - costPercent) / costPercent

            return {
                campaignId,
                campaignName,
                campaignGroupName,
                costPercent,
                convPercent: conversionsPercent,
                costConvDiff,
                valuePercent: conversionsValuePercent,
                costValueDiff,
                cpa,
                cpaDiff,
                roas,
                roasDiff,
                action: suggestedDailySpendPercent?.toString(),
                cost,
                searchImpressionsShare,
                shareLostToBudget,
                shareLostToRank,
                dailyBudget,
                avgDailySpend,
                suggestedDailySpend,
                estimatedConvChange: rollingEstimatedConvChange,
                estimatedConvValueChange: rollingEstimatedConvValueChange,
                conversions,
                conversionsValue,
                campaignSharedBudgetName,
                isSharedBudget,
            }
        })
    }
)

// Over/under Allocated Table Summary
const campaignSpendAllocationHeaders = computed(
    (): {
        key: string
        text: string
        width?: number
        sortable?: boolean
        noPadding?: boolean
    }[] => {
        const performanceSpecificHeaders = props.isUsingCpa
            ? [
                  { key: 'convPercent', text: 'Conv. %', noPadding: true, width: 68 },
                  { key: 'cpa', text: 'CPA', noPadding: true, width: 72 },
                  { key: 'cpaDiff', text: 'vs Avg.', noPadding: true, width: 86 },
              ]
            : [
                  { key: 'valuePercent', text: 'Value %', noPadding: true, width: 72 },
                  { key: 'roas', text: 'ROAS', noPadding: true, width: 72 },
                  { key: 'roasDiff', text: 'vs Avg.', noPadding: true, width: 76 },
              ]

        return [
            { key: 'campaignName', text: 'Campaign', noPadding: true },
            { key: 'costPercent', text: 'Cost %', noPadding: true, width: 72 },
            ...performanceSpecificHeaders,
        ]
    }
)

const totalCost = props.details.totalSpend
const totalConv = props.details.totalConversions
const totalConvValue = props.details.totalConvValue

const averageCpa = props.details.avgCpa
const averageRoas = props.details.avgRoas

const overAllocatedCampaignsItems = props.details.campaignsSpendDetailsOverAllocated.map(
    campaign => {
        const {
            diff,
            cpa,
            roas,
            conversionsValuePercent,
            costPercent,
            conversionsPercent,
            campaignName,
            suggestedDailySpendPercent,
        } = campaign

        const cpaDiff = (cpa - averageCpa) / averageCpa
        const roasDiff = (roas - averageRoas) / averageRoas

        const costConvDiff = (conversionsPercent - costPercent) / costPercent
        const costValueDiff = (conversionsValuePercent - costPercent) / costPercent

        const action = suggestedDailySpendPercent?.toString()

        return {
            campaignName,
            costPercent,
            convPercent: conversionsPercent,
            costConvDiff,
            valuePercent: conversionsValuePercent,
            costValueDiff,
            cpa,
            cpaDiff,
            roas,
            roasDiff,
            diff,
            action,
        }
    }
)

const underAllocatedCampaignsItems = props.details.campaignsSpendDetailsUnderAllocated.map(
    campaign => {
        const { cost, conversions, conversionsValue, suggestedDailySpendPercent } = campaign

        const cpa = cost / conversions
        const roas = conversionsValue / cost

        const cpaDiff = (cpa - averageCpa) / averageCpa
        const roasDiff = (roas - averageRoas) / averageRoas

        const costPercent = cost / totalCost
        const convPercent = conversions / totalConv
        const valuePercent = conversionsValue / totalConvValue
        const costConvDiff = (convPercent - costPercent) / costPercent
        const costValueDiff = (valuePercent - costPercent) / costPercent

        const action = suggestedDailySpendPercent?.toString()

        return {
            ...campaign,
            costPercent,
            convPercent,
            costConvDiff,
            valuePercent,
            costValueDiff,
            cpa,
            cpaDiff,
            roas,
            roasDiff,
            action,
        }
    }
)

const formatNumber = (value?: number): string => {
    const numericValue = value ?? 0
    const isInteger = Math.round(numericValue) === numericValue
    if (isInteger) return numericValue.toString()
    return numericValue.toFixed(2)
}

const createAnalysisText = ({
    diff,
    spendChange,
}: {
    diff: number
    spendChange: number
}): string => {
    const formattedSpendChange = useMoney({
        value: spendChange,
        currency: props.domainCurrency,
    }).displayValue.value

    const direction = diff > 0 ? 'Increase' : diff < 0 ? 'Decrease' : ''

    const formattedDiff = usePercent({ value: diff }).displayValue.value

    const intent =
        diff > 0
            ? 'Aiming for 100% Search Impression Share'
            : diff < 0
              ? 'Reallocate to higher performing campaigns'
              : 'Maintain daily spend'

    const actionableText = !diff
        ? ''
        : `${direction} spend by ${formattedDiff} (${formattedSpendChange}) -- `
    return `${actionableText}${intent}`
}

const downloadSpendAllocationReport = () => {
    const workbook = new ExcelJS.Workbook()

    const isUsingCpa = props.isUsingCpa
    const columns: Partial<ExcelJS.Column>[] = [
        { header: 'Account', key: 'account' },
        { header: 'Campaign Group', key: 'campaignGroupName' },
        { header: 'Campaign', key: 'campaignName' },
        { header: 'Campaign ID', key: 'campaignId' },
        { header: 'Cost', key: 'cost' },
        { header: 'Cost %', key: 'costPercent' },
        ...(isUsingCpa
            ? [
                  { header: 'Conv.', key: 'conversions' },
                  { header: 'Conv. %', key: 'convPercent' },
                  { header: 'Diff.', key: 'costConvDiff' },
                  { header: 'CPA', key: 'cpa' },
                  { header: 'Vs Avg.', key: 'cpaDiff' },
              ]
            : [
                  { header: 'Value', key: 'conversionsValue' },
                  { header: 'Value %', key: 'valuePercent' },
                  { header: 'Diff.', key: 'costValueDiff' },
                  { header: 'ROAS', key: 'roas' },
                  { header: 'Vs Avg.', key: 'roasDiff' },
              ]),
        { header: 'Search Impression Share', key: 'searchImpShare' },
        { header: 'Search Lost IS (Budget)', key: 'lostIsBudget' },
        { header: 'Search Lost IS (Rank)', key: 'lostIsRank' },
        { header: 'Daily Budget', key: 'dailyBudget' },
        { header: 'Avg. Daily Spend', key: 'avgDailySpend' },
        { header: 'Suggested Daily Spend', key: 'suggestedDailySpend' },
        { header: 'Daily Spend Diff.', key: 'dailySpendDiff' },
        { header: 'Recommended Action', key: 'recAction' },
        ...(isUsingCpa
            ? [{ header: 'Est. Conv Change (90d)', key: 'estConvChange' }]
            : [{ header: 'Est. Value Change (90d)', key: 'estValueChange' }]),
    ]
    const rows = campaignBreakdownTableItems.value?.map(c => {
        const {
            campaignGroupName,
            campaignName,
            campaignId,
            costPercent,
            cost,
            searchImpressionsShare: searchImpShare,
            shareLostToBudget: lostIsBudget,
            shareLostToRank: lostIsRank,
            dailyBudget,
            avgDailySpend,
            suggestedDailySpend,
            estimatedConvChange,
            estimatedConvValueChange,
            conversions,
            conversionsValue,
            costConvDiff,
            convPercent,
            cpa,
            cpaDiff,
            valuePercent,
            costValueDiff,
            roas,
            roasDiff,
            action,
            campaignSharedBudgetName,
            isSharedBudget,
        } = c
        return {
            account: props.domainName!,
            campaignGroupName,
            campaignName,
            campaignId: formatNumber(campaignId),
            cost: formatNumber(cost),
            costPercent: formatNumber(costPercent),
            ...(isUsingCpa && {
                conversions: formatNumber(conversions),
                convPercent: formatNumber(convPercent),
                costConvDiff: formatNumber(costConvDiff),
                cpa: formatNumber(cpa),
                cpaDiff: formatNumber(cpaDiff),
            }),
            ...(!isUsingCpa && {
                conversionsValue: formatNumber(conversionsValue),
                valuePercent: formatNumber(valuePercent),
                costValueDiff: formatNumber(costValueDiff),
                roas: formatNumber(roas),
                roasDiff: formatNumber(roasDiff),
            }),
            searchImpShare: formatNumber(searchImpShare),
            lostIsBudget: formatNumber(lostIsBudget),
            lostIsRank: formatNumber(lostIsRank),
            dailyBudget: `${formatNumber(dailyBudget ?? 0)} ${
                isSharedBudget ? `(${campaignSharedBudgetName})` : ''
            }`,
            avgDailySpend: formatNumber(avgDailySpend),
            suggestedDailySpend: formatNumber(suggestedDailySpend),
            dailySpendDiff: formatNumber(suggestedDailySpend - avgDailySpend),
            recAction: createAnalysisText({
                spendChange: suggestedDailySpend - avgDailySpend,
                diff: +action,
            }),
            ...(isUsingCpa && {
                estConvChange: formatNumber(estimatedConvChange - conversions),
            }),
            ...(!isUsingCpa && {
                estValueChange: formatNumber(estimatedConvValueChange - conversionsValue),
            }),
        }
    })

    const title = `Spend Allocation`
    const spendAllocation = workbook.addWorksheet(title)
    spendAllocation.columns = adjustWidthsToFitText({ columns, rows })
    spendAllocation.addRows(rows)

    // apply global styles
    workbook.eachSheet(worksheet => {
        const headerRow = worksheet.getRow(1)
        headerRow.font = { ...headerRow.font, bold: true }
        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.font = { ...cell.font, name: 'Arial', family: 2, size: 10 }
                cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true }
            })
        })
        worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
    })

    const date = new Date()
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    const formatter = new Intl.DateTimeFormat('en-US', options)
    const formattedDate = formatter.format(date)
    downloadExcelFile({
        title: `Campaign Spend Allocation (${formattedDate}) — ${props.domainName}`,
        workbook,
    })
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.summary-container {
    @include container;
    @include opteo-border;
    @include br-20;
    overflow: hidden;

    .chart-area {
        padding: 2.5rem 2.75rem 1.25rem 1rem;
        border-bottom: 1px solid;
        @include opteo-border;
    }

    .note-container {
        padding: 1rem 1.5rem 1.1875rem 1.5rem;
        border-top: 1px solid;
        @include opteo-border;
    }

    .key-statistics-container {
        @include w-100;
        @include grid;
        grid-template-columns: 1fr 1fr;

        .key-statistic-item {
            @include ph-24;
            @include pv-20;
            border-bottom: 1px solid #f7f7f9;

            .statistic {
                @include flex;
                @include items-center;
            }
        }
        .key-statistic-item:last-child {
            border-bottom: none;
        }
        .key-statistic-item:nth-child(odd) {
            border-right: 1px solid #f7f7f9;
        }
        .key-statistic-item:nth-last-child(-n + 2) {
            border-bottom: none;
        }
    }
}

.sub-header {
    @include flex;
    @include items-center;
    @include justify-between;
}

:deep(.improvement-note) {
    @include br-20;
}

.panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-36;
}

@media screen and (max-width: $mq-767-max) {
    .summary-container {
        .chart-area {
            @include pa-24;
        }

        .key-statistics-container {
            @include block;

            .key-statistic-item,
            .key-statistic-item:nth-last-child(-n + 2) {
                @include ph-24;
                @include pv-20;
                border-bottom: 1px solid #f7f7f9;
            }
            .key-statistic-item:last-child {
                border-bottom: none;
            }
            .key-statistic-item:nth-child(odd) {
                border-right: unset;
            }
        }
    }
}
</style>
